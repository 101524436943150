import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

const CurrentSalesOrder = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [options, setOptions] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const elementRef = useRef(null);

  const apiUrl = 'https://payroll.peoplehrm.in/get-sales-list';

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setNewSales(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, draw, start, length, selectedRole]);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [

        { data: 'order_date' },
        { data: 'order_date' },
        {
          data: 'reference_no',
          render: data => `<a href="#">${data}</a>`,
        },
        {
          data: 'company_name',
          render: (data) => (
            `<p ref=${elementRef} data-tip={showTooltip ? data : ''} className="cut-text" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>${data}</p>`
          ),
        },
        { data: 'order_amount' },
        { data: 'previous_due' },
        { data: 'previous_due' },
        {
          data: 'previous_due',

        },
        { data: 'status' },

        {
          data: null,
          render: (data, type, row) => (
            `<div class="filter verti-popdot">
              <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false"> Action <i class="bi bi-three-dots-vertical"></i> </a>
              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                

                <li><a class="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#download-delivery">Download PF</a></li>
                <li><a class="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#download-pf">Email PF</a></li>
                <li><a class="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#email-pf">Convert to S.O</a></li>
                <li><a class="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#convert-inv">Delete Order</a></li>
                <li><a class="deductionbtn reject-btn" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#createinvoive">Create Invoice</a></li>
                <li><a class="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#download-inv">Download Delivery Note</a></li>
                <li><a class="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#create-po">Create a P.O</a></li>
                
               </ul>
            </div>`
          ),
        },

        { data: 'Checkbox' },
      ],
    });

    return () => {
      table.destroy();
    };
  }, [newSales]);

  useEffect(() => {
    const handleMouseEnter = () => {
      if (elementRef.current && elementRef.current.offsetWidth < elementRef.current.scrollWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };

    const element = elementRef.current;
    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, [elementRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleAcceptOrder = async () => {
    const formData = $("#newsalesData").serialize();

    try {
      const response = await fetch(`${apiUrl}/sales/add_orders_front_sheet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const result = await response.json();
      if (result.status === 'true') {
        alert('Order Successfully Accepted');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        alert('Please select an order first');
      }
    } catch (error) {
      console.error('Error accepting order:', error);
    }
  };

  const updateReject = async () => {
    const formData = $("#rejectForm").serialize();
    try {
      const response = await fetch(`${apiUrl}/ws-rejectOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const result = await response.json();
    } catch (error) {
      console.error('Error rejecting order:', error);
    }
  };

  const rejectOrder = async (formData, order_id) => {
    try {
      const response = await fetch(`${apiUrl}/ws-getRejectOrderData`, {
        method: 'POST',
        headers: {
          authorization: 'Bearer ebu',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch reject order data');
      }
      const data = await response.json();
      document.getElementById('customer_name').textContent = data.data.company;
      document.getElementById('amount').textContent = data.data.grand_total;
      document.getElementById('reference_no').textContent = data.data.reference_no;
      document.getElementById('previous_dues').textContent = data.previous_dues;
      document.getElementById('order_id').value = order_id;
    } catch (error) {
      console.error('Error fetching reject order data:', error);
    }
  };

  const updateCustomer = async () => {
    const formData = $("#updateCustomerForm").serialize();
    try {
      const response = await fetch(`${apiUrl}/ws-updateOrderCustomer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const result = await response.json();
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  const fetchCustomerOptions = async () => {
    try {
      const response = await fetch(`${apiUrl}/ws-CustomerOption`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ebu`,
        },
      });

      const result = await response.json();

      const formattedOptions = result.data.customer.map(item => ({
        key: item.company,
        value: item.id,
      }));

      const $select = $('.customerList');
      $select.empty();
      formattedOptions.forEach(option => {
        const $option = $('<option>', { value: option.value, text: option.key });
        $select.append($option);
      });

      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching customer options:', error);
    }
  };

  return (
    <div>

      {/* Mark Deliver  */}
      <div class="modal fade" id="createinvoive" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Confirm Invoice</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="add-product-popup">
                <form>
                  <div class="row mb-3 padd-l-13px">
                    <label for="desc" class="col-sm-3 col-form-label">Order ID</label>
                    <div class="col-sm-9">
                      12745
                    </div>

                  </div>

                  <div class="row mb-3 padd-l-13px">
                    <label for="desc" class="col-sm-3 col-form-label">Customer Name</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" />
                    </div>

                  </div>

                  <div class="purchaseftr">
                    <div class="row padd-l-13px">
                      <div class="col-6 col-lg-4 col-sm-4">Gross Value : 4560.00</div>
                      <div class="col-6 col-lg-4 col-sm-4">Gross Profit : 15469.00</div>
                      <div class="col-6 col-lg-4 col-sm-4">Gross Profit % : 30%</div>
                    </div></div>

                  {/* <div class="row mb-3 padd-l-13px">

                    <label for="desc" class="col-sm-3 col-form-label">Select Bank</label>
                    <div class="col-sm-9">
                      <select class="form-select" aria-label="Default select example">
                        <option selected="">From Currency wise Bank Details</option>
                        <option value="1">Hsbc (Ac No)</option>
                        <option value="2">Icici (Ac No)</option>

                      </select>
                      <span>(From Currency wise Bank Details)</span>
                    </div>
                  </div> */}



                  <div class="row mb-3 padd-l-13px">

                    <label for="desc" class="col-sm-3 col-form-label">Notes</label>
                    <div class="col-sm-9">
                      <textarea class="form-control" ></textarea>
                    </div>
                  </div>

                  <hr />
                  <div class="form-group">
                    <a href='/OrderHistory' className='popup-innerbtn-common'>Create Invoice</a>

                  </div>

                </form>
              </div>
            </div>
           
          </div>
        </div>
      </div>

      <div class="modal fade" id="partial-popup" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">&nbsp;</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="partialbox">
                <h3>All Batches : CIF CREAM LEMON (CIF016)</h3>
                <table class="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Expiry</th>
                      <th>Stock Qty</th>
                      <th>Sold Qty</th>
                      <th>Bal Qty</th>
                      <th>Alloc</th>
                      <th>UnAlloc</th>
                      <th>PO No.</th>
                      <th>On P.O</th>
                      <th>Net Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" checked /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>0</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>0</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>0</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>

              </div>

              <div class="partialbox">
                <h3>All Batches : SKITTLES WILD BERRY (SKI012)</h3>
                <table class="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Expiry</th>
                      <th>Stock Qty</th>
                      <th>Sold Qty</th>
                      <th>Bal Qty</th>
                      <th>Alloc</th>
                      <th>UnAlloc</th>
                      <th>PO No.</th>
                      <th>On P.O</th>
                      <th>Net Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" checked /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>0</td>
                      <td>&nbsp;</td>
                    </tr>

                  </tbody>
                </table>

              </div>

              <div class="partialbox">
                <h3>All Batches : SKITTLES FRUITS (SKI011)</h3>
                <table class="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Expiry</th>
                      <th>Stock Qty</th>
                      <th>Sold Qty</th>
                      <th>Bal Qty</th>
                      <th>Alloc</th>
                      <th>UnAlloc</th>
                      <th>PO No.</th>
                      <th>On P.O</th>
                      <th>Net Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" checked /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>0</td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" checked /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>1400</td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td><input class="" type="radio" name="gridRadios" value="option1" checked /></td>
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target="_blank">6484</a></td>
                      <td>0</td>
                      <td>&nbsp;</td>
                    </tr>

                  </tbody>
                </table>

              </div>


            </div>

          </div>
        </div>
      </div>

      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt;
              <a href="dashboard.html">Dashboard</a> &gt;
              <span className="last-crumb">Current Sales Order</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="dashform">
                <div class="topsearch-area">
                  <div style={{ float: 'left' }}>
                    <label>Search OrderID/Customer</label>
                    <input type="text" class="search-area" placeholder="Search" autocomplete="off" value="" />
                    <label>To Date</label>
                    <input
                      type="date"
                      name="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      className="form-control"
                    />
                    <button type="submit" onClick={handleSubmit} className="selectdate-btn">
                      Submit
                    </button>
                  </div>
                </div>
                </div>

                {/* <button type="submit" onClick={handleAcceptOrder} className="create-catalogue-btn">
                  Accept Order
                </button>
                <a href="NewSalesOrder" className='create-catalogue-btn'>New Sales Order</a> */}

                <div className="verical-scrl-wrapper newsales-table" id="style-3">
                  <form id="newsalesData">
                    <table className="table table-bordered dataTable" id="neworderTable1">
                      <thead>
                        <tr>

                          <th>Order Date</th>
                          <th>Expiry Date</th>
                          <th>Order Id</th>
                          <th>Customers(ID)</th>
                          <th>Order Amount</th>
                          <th>Previous Dues</th>
                          <th>Total Dues</th>
                          <th>P.O</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Transport</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newSales.map((item, index) => (
                          <tr key={index}>

                            <td>25/07/2024</td>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                name="expiryDate"
                              />
                            </td>
                            <td><a href="#">{item.reference_no}</a></td>
                            <td>
                            <a href='#'> <p ref={elementRef} data-tip={showTooltip ? item.customer : ''} className="cut-text" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {item.customer} TS Chilled Foods (TSC004)
                              </p></a> 
                            </td>
                            <td>{item.postal_code}15469.00</td>
                            <td>{item.route_number}1000.00</td>
                            <td>{item.prev_due}14469.00</td>
                            <td><a href='#' data-bs-toggle="modal" data-bs-target="#partial-popup">{item.grand_total} Partial</a></td>
                            <td>{item.name}PF</td>
                            <td>
                              <div class="btn-group">
                                <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                  Action
                                </button>
                                <ul class="dropdown-menu dropdown-menu-arrow">
                                  <li><a className="applybtn update-customer" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#change-customer">
                                    Download PF
                                  </a>
                                  </li>
                                  <li><a className="applybtn update-customer" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#change-customer">
                                    Email PF
                                  </a></li>
                                  <li><a className="applybtn update-customer" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#change-customer">
                                    Convert to S.O
                                  </a></li>
                                  <li><a className="applybtn update-customer" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#change-customer">
                                    Delete Order
                                  </a></li>

                                  <li> <a className="deductionbtn reject-btn" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#createinvoive">
                                    Create Invoice
                                  </a></li>
                                  <li><a className="applybtn update-customer" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#change-customer">
                                    Download Deliver Note
                                  </a></li>
                                  <li> <a className="applybtn update-customer" href="#" data-id={item.ids} data-bs-toggle="modal" data-bs-target="#change-customer">
                                    Create a P.O
                                  </a></li>
                                </ul>
                              </div>
                            </td>
                            <td><label class="checkbox"> <input type="checkbox" /> <span class="default"></span> </label></td>
                          </tr>
                        ))}
                      </tbody>



                    </table>
                  </form>
                </div>
                <div class="purchaseftr">
                  <div class="row">
                    <div class="col-6 col-lg-3 col-sm-3">Gross Total : 15469.00</div>
                    
                    </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CurrentSalesOrder;
