import React, { useState, useEffect, useRef} from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import Swal from 'sweetalert2';
import { render } from '@testing-library/react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';

const WarehouseMaster = () => {
    const [warehouseData, setWarehouseData] = useState([]);
    const modalRef = useRef(null);
    const editModalRef = useRef(null);    
    const tableRef = useRef(null); 

    // Validation schema using Yup
    const validationSchema = Yup.object({
      name: Yup.string().required('Warehouse Name is required'),
      code: Yup.string().required('Warehouse Code is required'),
      contact_person_name: Yup.string().required('Contact Person Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      mobile: Yup.string().required('Mobile Number is required'),
      telephone_no: Yup.string().required('Telephone Number is required'),
      address_line1: Yup.string().required('Address Line 1 is required'),
      state: Yup.string().required('State is required'),
      address_line2: Yup.string().optional(),
      country: Yup.string().required('Country is required'),
      city: Yup.string().required('City is required'),
      postcode: Yup.string().required('Postcode is required'),
      status: Yup.boolean(),
    });
    
    const initializeDataTable = () => {
      const $table = $(tableRef.current);
      const searchValue = $('#searchValue').val();

      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      // Initialize DataTable with server-side processing
      $table.DataTable({
          processing: true,
          serverSide: true,
          orderable:false,

          ajax: {
              url: `${config.api_url}/ws-warehouse-list`,
              type: 'POST',
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                  return json.data;
              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },

          columns: [
              { data: 'code', width: "6%" },
              { data: 'name' },
              { data: 'contact_person_name' },
              { data: 'mobile', width: "10%" },
              { data: 'city', width: "10%" },
              { data: 'postcode', width: "10%" },
              { data: 'status',
                width: "7%",
                render: function(data, type, row)
                {
                    let status = row.status === '1' ? 'Active' : 'Inactive' ;
                    return status;
                }
               },
               {
                data: null,
                width: "7%",
                title: 'Action',
                render: (data, type, row) => `
                    <a href="#" class="editbtn" data-id="${row.id}" title="Edit Warehouse" data-bs-toggle="modal" data-bs-target="#edit_warehouse">
                        <i class="fa fa-edit"></i> 
                    </a>`
              },
                        ],


          columnDefs: [
            { targets: '_all',orderable: false },
            {
                targets: 6, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
          pageLength: 50,
      });
  

      // Event delegation for edit and view actions
      $table.on('click', '.editbtn', function() {
          const id = $(this).data('id');
          handleEditClick(id);
      });
  };

  useEffect(() => {
    initializeDataTable();

    return () => {
        if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
        };
    }, []);


    const handleAddWarehouse = async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        for (let key in values) {
          formData.set(key, values[key]);
        }
        formData.set("status", values.status ? 1 : 0);
  
        const response = await axios.post(
          `${config.api_url}/ws-add-warehouse`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Warehouse added successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            if (modalRef.current) {
              const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
              if (bsModal) bsModal.hide();
            }
  
            initializeDataTable();
            resetForm(); // Reset the form after successful submission
          });
        } else {
          Swal.fire({
            title: "Error!",
            text:  "An error occurred while adding a warehouse.",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error("Request Error:", error);
        Swal.fire({
          title: "Error!",
          text: "An error occurred while adding a warehouse.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    };

    const handleEditClick = async (id) => {
        document.getElementById("editNewWarehouseForm").reset();
        const  formData = new FormData();
        formData.set('warehouse_id', id);
          const response = await axios.post(`${config.api_url}/ws-get-warehouse-by-id`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
                }
          }); 
          
           
          if (response.data.success) 
            {
                $('#name').val(response.data.warehouse[0].name);
                $('#warehouse_id').val(id);
                $('#code').val(response.data.warehouse[0].code);
                $('#country').val(response.data.warehouse[0].country);
                $('#contact_person_name').val(response.data.warehouse[0].contact_person_name);
                $('#email').val(response.data.warehouse[0].email);
                $('#mobile').val(response.data.warehouse[0].mobile);
                $('#telephone_no').val(response.data.warehouse[0].telephone_no);
                $('#address_line1').val(response.data.warehouse[0].address_line1);
                $('#address_line2').val(response.data.warehouse[0].address_line2);
                $('#state').val(response.data.warehouse[0].state);
                $('#city').val(response.data.warehouse[0].city);
                $('#postcode').val(response.data.warehouse[0].postcode);
                (response.data.warehouse[0].status == "1") ? $('#status').prop('checked', true) : $('#status').prop('checked', false);
            }
        };

    const handleEditWarehouse = async (e) => { 
            e.preventDefault();
            try {
              const formElement = document.getElementById("editNewWarehouseForm");
              const formData = new FormData(formElement);
              formData.set("status", formData.get("status") === "on" ? 1 : 0);
              const response = await axios.post(
                `${config.api_url}/ws-update-warehouse`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              if (response.data.success) {
                Swal.fire({
                  title: "Success!",
                  text: "Warehouse updated successfully.",
                  icon: "success",
                  timer: 1500, 
                  showConfirmButton: false,
                })
                  .then(() => {
                    if (editModalRef.current) {
                      const bsModal = window.bootstrap.Modal.getInstance(editModalRef.current);
                      if (bsModal) bsModal.hide();
                    }
                    initializeDataTable();
                    document.getElementById("editNewWarehouseForm").reset();
                  })
                  .catch((error) => {
                    console.error("Error refreshing warehouse:", error);
                  });
              } else {
                Swal.fire({
                  title: "Error!",
                  text: "An error occurred while updating a warehouse.",
                  icon: "error",
                  timer: 1500,
                  showConfirmButton: false,
                });
              }
            } catch (error) {
              console.error("Request Error:", error);
              Swal.fire({
                title: "Error!",
                text: "An error occurred while updating a warehouse.",
                icon: "error",
                timer: 1500, 
                showConfirmButton: false,
              });
            }
        };

  return (
    <div>
       <div className="modal fade" id="add-warehouse" tabIndex="-1" ref={modalRef}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Warehouse</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className='warehousemaster-area'>
              <Formik
                initialValues={{
                  name: '',
                  code: '',
                  contact_person_name: '',
                  email: '',
                  mobile: '',
                  telephone_no: '',
                  address_line1: '',
                  state: '',
                  address_line2: '',
                  country: '',
                  city: '',
                  postcode: '',
                  status: true,
                }}
                validationSchema={validationSchema}
                onSubmit={handleAddWarehouse}
              >
                {({ values }) => (
                   <Form id="addNewWarehouseForm">
                   <div className="row mb-3 padd-l-13px">
                     <label className="col-sm-3 col-form-label">Warehouse Name *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="name" />
                       <ErrorMessage name="name" component="div" className="text-danger" />
                     </div>
                     <label className="col-sm-3 col-form-label">Warehouse Code *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="code" />
                       <ErrorMessage name="code" component="div" className="text-danger" />
                     </div>
                   </div>
                   <div className="row mb-3 padd-l-13px">
                     <label className="col-sm-3 col-form-label">Contact Person *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="contact_person_name" />
                       <ErrorMessage name="contact_person_name" component="div" className="text-danger" />
                     </div>
                     <label className="col-sm-3 col-form-label">Mobile No. *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="mobile" />
                       <ErrorMessage name="mobile" component="div" className="text-danger" />
                     </div>
                   </div>
                   <div className="row mb-3 padd-l-13px">
                     <label className="col-sm-3 col-form-label">Email ID *</label>
                     <div className="col-sm-3">
                       <Field type="email" className="form-control" name="email" />
                       <ErrorMessage name="email" component="div" className="text-danger" />
                     </div>
                     <label className="col-sm-3 col-form-label">Telephone No. *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="telephone_no" />
                       <ErrorMessage name="telephone_no" component="div" className="text-danger" />
                     </div>
                   </div>
                   <h4>Delivery Address  </h4>
                   <div className="row mb-3 padd-l-13px">
                     <label className="col-sm-3 col-form-label">Address Line 1 *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="address_line1" />
                       <ErrorMessage name="address_line1" component="div" className="text-danger" />
                     </div>
                     <label className="col-sm-3 col-form-label">Address Line 2</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="address_line2" />
                       <ErrorMessage name="address_line2" component="div" className="text-danger" />
                     </div>
                   </div>

                   <div className="row mb-3 padd-l-13px">
                     <label className="col-sm-3 col-form-label">State *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="state" />
                       <ErrorMessage name="state" component="div" className="text-danger" />
                     </div>
                     <label className="col-sm-3 col-form-label">Country *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="country" />
                       <ErrorMessage name="country" component="div" className="text-danger" />
                     </div>
                   </div>

                   <div className="row mb-3 padd-l-13px">
                     <label className="col-sm-3 col-form-label">City *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="city" />
                       <ErrorMessage name="city" component="div" className="text-danger" />
                     </div>
                     <label className="col-sm-3 col-form-label">Postcode *</label>
                     <div className="col-sm-3">
                       <Field type="text" className="form-control" name="postcode" />
                       <ErrorMessage name="postcode" component="div" className="text-danger" />
                     </div>
                   </div>

                   <div className="row mb-3 padd-l-13px">
                     <label htmlFor='status' className="col-sm-3 col-form-label">Status</label>
                     <div className="col-sm-3">
                       <label className="switch">
                        <Field className="switch-input" type="checkbox" name='status' id='status' />
                        <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                       </label>
                     </div>
                   </div>

                   <hr />
                   <div className="row padd-l-13px">
                     <div className="col-sm-12">
                       <button type="submit" className="popup-innerbtn-common right">Save</button>
                     </div>
                   </div>
                 </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="modal fade" id="edit_warehouse" tabIndex="-1" ref={editModalRef} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Warehouse</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className='warehousemaster-area'>
            <form id='editNewWarehouseForm'>   
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="desc" className="col-sm-3 col-form-label">Warehouse Name *</label>
                <div className="col-sm-3">
                <input type="hidden" className="form-control" name="warehouse_id" id='warehouse_id'  />
                    <input type="text" className="form-control" name="name" id='name'  />
                </div>
                <label htmlFor="compType" className="col-sm-3 col-form-label">Warehouse Code </label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="code" id='code'  />
                </div>
            </div>
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Contact person name</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="contact_person_name" id='contact_person_name' />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Email ID</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="email" id='email' />
                </div>
            </div>
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Mobile No.</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="mobile" id='mobile' />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Telephone No.</label>
                <div className="col-sm-3">
                <input type="text" className="form-control" name="telephone_no" id='telephone_no'  />
                </div>
            </div>
            <h4>Delivery Address  </h4>
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Address Line 1</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="address_line1" id='address_line1'  />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">State</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="state" id='state'  />
                </div>
            </div>
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Address Line 2 </label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="address_line2" id='address_line2' />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Country</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="country" id='country'  />
                </div>
            </div>
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">City</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="city" id='city'  />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Post Code</label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name="postcode" id='postcode'  />
                </div>
            </div>
            <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-3">
                    <label className="switch">
                        <input className="switch-input" type="checkbox" name='status' id='status' />
                        <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                    </label>
                </div>
            </div>
            <hr />
            <div className="row padd-l-13px">
                <div className="col-sm-12">
                    <button type="submit" onClick={handleEditWarehouse}  className="popup-innerbtn-common right">Update</button>
                </div>
            </div>
            </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span className="last-crumb">Warehouse</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                    <div className='warehouse-tbl'>
                    <div class="topsearch-area">
                                <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                <div class="row">
                                  <label htmlFor="inputText" class="col-sm-3 col-form-label">Warehouse:</label>
                                  <div class="col-sm-6">
                                  <input type="text" className="form-control" name="searchValue" id="searchValue" onChange={initializeDataTable} placeholder="Search Warehouse" />
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{float: "right" }}>
                                <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add-warehouse">Add New Warehouse</a>
                                </div>
                              </div>
                            </div>        
                        </div>
                    
                        <div style={{width: '100%', float: 'left' }}>
                        <div className="verical-scrl-wrapper" id="style-3">
                            <table className="table table-bordered dataTable" ref={tableRef} >
                            <thead>
                                <tr>
                                {/* <th>Emp Id</th> */}
                                <th>Code</th>
                                <th>Name</th>
                                <th>Contact Person Name</th>
                                <th>Mobile</th>
                                <th>city</th>
                                <th>postcode</th>
                                <th>Status</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default WarehouseMaster;
