import React, { useState, useEffect, useRef  } from "react";
import { Link } from "react-router-dom";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from '../Common_Files/Config';
import ViewProduct from '../Master/ViewProduct';
import SupplierSearch from '../Purchase/SupplierSearch';
import ProductBatch from './ProductBatch'; 
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from 'sweetalert2';
import axios from 'axios';
import Select from 'react-select';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useNavigate } from "react-router-dom";

//fetchSupplierData
import { fetchProductData,fetchWarehouseDeliveryData, changeWarehouseAddress, handleInputChange, removeRow, calculateValue, acceptNumber, getAddressById,handleChange1 } from '../../services/NewPurchaseOrderService';

const NewPurchaseOrder = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [product, setProduct] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [discTableRows, setDiscTableRows] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [warehouseDeliveryAddress, setWarehouseDeliveryAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [productBatch, setProductBatch] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [productId, setProductId] = useState(0);
  const navigateToGRNList = useNavigate();
  const discModal = useRef(null);

  const [customerOptions, setCustomerOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState('');
  const [picker, setSelectedProduct] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const productBatchRef = useRef(null);

  const [modalData, setModalData] = useState(null);
  const handleOpenPastPurchaseModal = (data) => {  
    setModalData(data); 
    if (productBatchRef.current) {
      productBatchRef.current(); 
      
    }
  };


  const [formData, setFormData] = useState({
    retro: '',
    promptPayment: '',
    costToServe: '',
    offInvoice: '',
    bedsLeds: '',
    sugroRetro: '',
  });

  const handleDiscount = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleShowPastPurchaseModal = (showModalHandler) => {
    showModalHandler();
  };


  useEffect(() => {
   // fetchSupplierData(setSupplierData);
   fetchProductData(setProductData);
   fetchWarehouseDeliveryData(setWarehouseDeliveryAddress); 
 }, []);



  const handleSubmit = async (e, tableRows, productId) => {
    e.preventDefault();

    const retro = parseFloat(formData.retro) || 0;
    const prompt_payment = parseFloat(formData.promptPayment) || 0;
    const cost_to_serve = parseFloat(formData.costToServe) || 0;
    const off_invoice = parseFloat(formData.offInvoice) || 0;  

    const initializedItems = tableRows.map(item => 
      item.id === productId
      ? {
        ...item,  
        promptPayment: prompt_payment,
        costToServe: cost_to_serve,
        offInvoice: off_invoice,
        bedsLeds: 0,
        sugroRetro: retro
      }
      : item
      );

    
    setTableRows(initializedItems);    
    
    const bsModal = window.bootstrap.Modal.getInstance(discModal.current);
    if (bsModal) bsModal.hide();

    document.getElementById('addDiscountForm').reset();

  };

  
  useEffect(() => {
    const newGrandTotal = tableRows.reduce((total, row) => total + row.value, 0);
    setGrandTotal(newGrandTotal);

    const totalVat = tableRows.reduce((vat, row) => vat + ((row.value.toFixed(2)-(parseFloat(row.promptPayment)+parseFloat(row.costToServe)+parseFloat(row.offInvoice)+parseFloat(row.bedsLeds)+parseFloat(row.sugroRetro)).toFixed(2))/100) * row.vat, 0);
    setVatAmt(totalVat);

    const stdGoods = tableRows.reduce((total, row) => {
      if (parseFloat(((row.value.toFixed(2)-(parseFloat(row.promptPayment)+parseFloat(row.costToServe)+parseFloat(row.offInvoice)+parseFloat(row.bedsLeds)+parseFloat(row.sugroRetro)).toFixed(2))/100) * row.vat) > 0) {
        return total + row.value;
      }
      return total; 
    }, 0);
    setStdGoods(stdGoods);

    const zeroGoods = tableRows.reduce((total, row) => {
      if (parseFloat(((row.value.toFixed(2)-(parseFloat(row.promptPayment)+parseFloat(row.costToServe)+parseFloat(row.offInvoice)+parseFloat(row.bedsLeds)+parseFloat(row.sugroRetro)).toFixed(2))/100) * row.vat) == 0) {
        return total + row.value;
      }
      return total; 
    }, 0);
    setZeroGoods(zeroGoods);

  }, [tableRows]);


  const handleProductChange = (inputValue) => {
    setSelectedProduct(inputValue);
  };

  const handleChange = (selectedOption) => {

    if (selectedOption && selectedOption.value) 
    {
      setProductInputValue(selectedOption);   
      handleChange1(selectedOption.value, tableRows,  setTableRows);
    } else 
    {
     setProductInputValue(null);   
     console.warn("Selected option is invalid or missing a value");
   }
 };


 const getProductBatchData = async (id) => {
  try {


   const formData = new FormData();
   formData.set('product_id', id);

   const response = await axios.post(
    `${config.api_url}/ws-batch-info-by-product-id`, 
    formData, 
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data', 
      },
    }
    );

   const data = response.data;
   setProductBatch(data.batch); 
   handleOpenPastPurchaseModal(data.batch);
 } catch (error) {
  console.error("Error fetching product batch data:", error);
}
};


const submitPurchaseOrderForm = async () => {

  const form = document.getElementById('newPurchaseForm');
  const formData = new FormData(form);
  const supplierId = formData.get('supplier_id');
  if (supplierId) 
  {

  } else 
  {      

    Swal.fire({
      title: "Error!",
      text: "Supplier is Required",
      icon: "error",
      timer: 1500, 
      showConfirmButton: false,
    })
    return;
  }      

  tableRows.forEach((row, index) => {
    formData.append(`code[${index}]`, row.code);
    formData.append(`expiryDate[${index}]`, row.expiryDate);
    formData.append(`packsize[${index}]`, row.pack);
    formData.append(`productSize[${index}]`, row.size);
    formData.append(`qty[${index}]`, row.quantity);
    formData.append(`price[${index}]`, row.price);
    formData.append(`value[${index}]`, row.value);
    formData.append(`Disc[${index}]`, row.disc);
    formData.append(`NetP[${index}]`, row.netP);
    formData.append(`Vat[${index}]`, row.vat);
    formData.append(`currrentStock[${index}]`, row.curStock);
    formData.append(`onSO[${index}]`, row.onSO);
    formData.append(`fstk[${index}]`, row.fstk);
  });

  try {
    const response = await axios.post(`${config.api_url}/ws-purchases-add`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ebu`,
      },
    });

    const result = response.data;
    Swal.fire({
      title: "Success!",
      text: "Order submitted successfully.",
      icon: "success",
      timer: 1500, 
      showConfirmButton: false,
    })
    navigateToGRNList('/GRN');
  } catch (error) {
    console.error("Error accepting order:", error);
  }
};

const handleDiscClick = (id) => {
  setProductId(id);
}

return (
  <div>

  <div className="modal fade" id="ON-PO" tabIndex="-1">
  <div className="modal-dialog modal-md">
  <div className="modal-content">
  <div className="modal-header">
  <h5 className="modal-title">(SKIO11) SKITTLES FRUITS</h5>
  <button
  type="button"
  className="btn-close"
  data-bs-dismiss="modal"
  aria-label="Close"
  ></button>
  </div>
  <div className="modal-body">
  <div className="partialbox">
  <h5>
  S.O: 12714 <span>D&D Snacks (D&D023)</span>
  </h5>
  <table className="table table-bordered dataTable">
  <thead>
  <tr>
  <th>Assign</th>
  <th>Order Qty</th>
  <th>On P.O</th>
  <th>Balance</th>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td>
  <input
  className=""
  type="radio"
  name="gridRadios"
  value="option1"
  defaultChecked
  />
  </td>
  <td>500</td>
  <td>1000</td>
  <td>500</td>
  </tr>
  </tbody>
  </table>
  </div>
  <div className="partialbox">
  <h5>
  S.O: 12800 <span>TS Chilled Food (TSC004)</span>
  </h5>
  <table className="table table-bordered dataTable">
  <thead>
  <tr>
  <th>Assign</th>
  <th>Order Qty</th>
  <th>On P.O</th>
  <th>Balance</th>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td>
  <input
  className=""
  type="radio"
  name="gridRadios"
  value="option1"
  defaultChecked
  />
  </td>
  <td>500</td>
  <td>1000</td>
  <td>500</td>
  </tr>
  </tbody>
  </table>
  </div>
  <div className="partialbox">
  <h5>
  S.O: 12802 <span>AAC & Co (AAC001)</span>
  </h5>
  <table className="table table-bordered dataTable">
  <thead>
  <tr>
  <th>Assign</th>
  <th>Order Qty</th>
  <th>On P.O</th>
  <th>Balance</th>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td>
  <input
  className=""
  type="radio"
  name="gridRadios"
  value="option1"
  defaultChecked
  />
  </td>
  <td>500</td>
  <td>1000</td>
  <td>500</td>
  </tr>
  </tbody>
  </table>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div className="modal fade" id="adnw-prct" tabIndex="-1">
  <div className="modal-dialog modal-lg">
  <div className="modal-content">
  <div className="modal-header">
  <h5 className="modal-title">&nbsp;</h5>
  <button
  type="button"
  className="btn-close"
  data-bs-dismiss="modal"
  aria-label="Close"
  ></button>
  </div>
  <div className="modal-body">
  <div className="partialbox">
  <h3>Search (Code/Description/Unit Barcode)</h3>
  <table className="table table-bordered dataTable">
  <thead>
  <tr>
  <th>Code</th>
  <th>Description</th>
  <th>PK</th>
  <th>Size</th>
  <th>Unit Barcode</th>
  <th>Stock</th>
  <th>F.Stk</th>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td>
  <a href="#" target="blank">
  SKI010
  </a>
  </td>
  <td>SKITTLES CRAZY SOURS</td>
  <td>36</td>
  <td>45GM</td>
  <td>&nbsp;</td>
  <td>324</td>
  <td>176</td>
  </tr>

  <tr>
  <td>
  <a href="#" target="blank">
  SKI010
  </a>
  </td>
  <td>SKITTLES CRAZY SOURS</td>
  <td>36</td>
  <td>45GM</td>
  <td>&nbsp;</td>
  <td>324</td>
  <td>176</td>
  </tr>
  <tr>
  <td>
  <a href="#" target="blank">
  SKI010
  </a>
  </td>
  <td>SKITTLES CRAZY SOURS</td>
  <td>36</td>
  <td>45GM</td>
  <td>&nbsp;</td>
  <td>324</td>
  <td>176</td>
  </tr>

  <tr>
  <td>
  <a href="#" target="blank">
  SKI010
  </a>
  </td>
  <td>SKITTLES CRAZY SOURS</td>
  <td>36</td>
  <td>45GM</td>
  <td>&nbsp;</td>
  <td>324</td>
  <td>176</td>
  </tr>
  </tbody>
  </table>
  </div>
  </div>
  </div>
  </div>
  </div>

  <ProductBatch onViewPastPurchase={(showModal) => { productBatchRef.current = showModal; }} getProductBatchData={getProductBatchData}
  modalData={modalData} />

  <div  className="modal fade" id="viw-disk" tabIndex="-1" ref={discModal}>
  <div  className="modal-dialog modal-sm">
  <div  className="modal-content">
  <div  className="modal-header">
  <h6  className="modal-title">&nbsp; Add Discount</h6>
  <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div  className="modal-body">
  <div  className="adddiscount-popup">
  <form onSubmit={(e) => handleSubmit(e, tableRows, productId)} id="addDiscountForm" >
  <table  className="table table-bordered dataTable">
  <tbody>
  <tr>
  <td>Retro</td>
  <td><input type="text" className="form-control"  name="retro" value={formData.retro}  onChange={handleDiscount}  /></td>
  </tr>
  <tr>
  <td>Prompt Payment</td>
  <td><input type="text"  className="form-control"  name="promptPayment" value={formData.promptPayment} onChange={handleDiscount} /></td>
  </tr>
  <tr>
  <td>Cost to Serve</td>
  <td><input type="text"  className="form-control"  name="costToServe" value={formData.costToServe} onChange={handleDiscount} /></td>
  </tr>
  <tr>
  <td>Off Invoice</td>
  <td><input type="text"  className="form-control"  name="offInvoice" value={formData.offInvoice}  onChange={handleDiscount}  /></td>
  </tr>
  <tr>
  <td>Beds & Leds</td>
  <td><input type="text"  className="form-control"  name="bedsLeds" value={formData.bedsLeds}  onChange={handleDiscount} /></td>
  </tr>
  <tr>
  <td>Sugro Retro</td>
  <td><input type="text"  className="form-control" name="sugroRetro" value={formData.sugroRetro}  onChange={handleDiscount} /></td>
  </tr>
  </tbody>  
  </table>
  <button class="popup-innerbtn-common" type="submit"   style={{ marginTop: '10px' }}>Submit</button>
  </form>
  </div>
  </div>
  </div>
  </div>
  </div> 
  <Header />
  <ViewProduct />
  <form id="newPurchaseForm">
  <div className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12">
  {" "}
  <a href="#">
  <i className="fa fa-home"></i>
  </a>{" "}
  &gt; <a href="dashboard">Dashboard</a> &gt;{" "}
  <span className="last-crumb">New Purchase Order</span>{" "}
  </div>
  </div>
  </div>
  </div>
  <div className="main-container">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12">
  <div className="dashboard">
  <div className="">
  <div className="dashform">
  <div className="topsearch-area">


  <div className="row">
  <div className="col-12 col-lg-3 col-sm-3">
  <div className="row">
  <label
  for="inputText"
    className="col-sm-4 col-form-label"
  >
  Supplier:
  </label>
  <div className="col-sm-8">
  <SupplierSearch />
  </div>
  </div>
  </div>
  <div className="col-12 col-lg-3 col-sm-3">
  <div className="row">
  <label
  for="inputText"
    className="col-sm-4 col-form-label"
  >
  Product:
  </label>
  <div className="col-sm-8">
  <Select
  isClearable
  onInputChange={handleProductChange}
  onChange={handleChange}
  options={productData}
  placeholder="Select Product"
  value={productInputValue}
  />
  </div>
  </div>  
  </div>
  </div>
  </div>
  </div>
  <div  className="verical-scrl-wrapper common-table purchase-no-tbl"  id="style-3"  >
  <table  className="table table-bordered dataTable" id="dsTable" >
  <thead>
  <tr>
  <th>Code</th>    
  <th>Description</th>
  <th>Pk</th>
  <th>Size</th>
  <th>Qty</th>
  <th>CPrice</th>
  <th>Value</th>
  <th>Disc</th>
  <th>NetP</th>
  <th>VAT</th>
  <th>CurStock</th>
  <th>On S.O</th>
  <th>FStk</th>
  <th>&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  {tableRows.length === 0 ? (
    <tr style={{ textAlign: "center" }}>
    <td colSpan={13}>No product added</td>
    </tr>
    ) : (
    tableRows.map((row) => {
      // Safely parse and calculate the values
      const value = parseFloat(row.value) || 0;
      const promptPayment = parseFloat(row.promptPayment) || 0;
      const costToServe = parseFloat(row.costToServe) || 0;
      const offInvoice = parseFloat(row.offInvoice) || 0;
      const bedsLeds = parseFloat(row.bedsLeds) || 0;
      const sugroRetro = parseFloat(row.sugroRetro) || 0;
      const vat = parseFloat(row.vat) || 0;

      const totalCosts = promptPayment + costToServe + offInvoice + bedsLeds + sugroRetro;
      const netPrice = (value - totalCosts).toFixed(2);
      const vatAmount = (((value - totalCosts) / 100) * vat).toFixed(2);

      return (
        <tr key={row.id}>
        <td>
        <input type="hidden" name="product_id[]" value={row.id} />
        <input type="hidden" name="code[]" value={row.code} />       
        <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();         
          getProductBatchData(row.id); 
        }}
        >
        {row.code}
        </Link>
        </td>
        <td>
        <p className="cut-text">
        <a
        data-bs-toggle="modal"
        className="applybtn"
        data-id={row.id}
        data-bs-target="#viewproduct"
        >
        {row.product_desc}
        </a>
        </p>
        </td>
        <td>
        <input type="hidden" name="packsize[]" value={row.pack} /> {row.pack}
        </td>
        <td>
        <input type="hidden" name="productSize[]" value={row.size} /> {row.size}
        </td>
        <td>
        <input
        type="text"
        name="qty[]"
        className="form-control qtyinputbox"
        defaultValue={0}
        onChange={(e) => handleInputChange(e, row.id, "quantity", setTableRows)}
        />
        </td>
        <td>
        <input
        type="text"
        name="cost[]"
        className="form-control qtyinputbox"
        value={row.price}
        onChange={(e) => handleInputChange(e, row.id, "price", setTableRows)}
        />
        </td>
        <td>
        <input
        hidden
        type="text"
        name="value[]"
        className="form-control qtyinputbox"
        value={value.toFixed(2)}
        readOnly
        />
        {value.toFixed(2)}
        </td>
        <td>
        <input hidden type="text" name="Disc[]" />{" "}
        <a
        href="#"
        data-id={row.id}
        onClick={() => handleDiscClick(row.id)} 
        data-bs-toggle="modal"
        data-bs-target="#viw-disk"
        >
        {totalCosts.toFixed(2)}
        </a>
        </td>
        <td>
        <input 
        type="hidden" 
        name="NetP[]"  
        value={netPrice} 
        />
        {netPrice}
        </td>
        <td>
        <input 
        type="hidden" 
        name="vat[]" 
        value={vatAmount}
        />
        {vatAmount}
        </td>
        <td>
        <input
        type="hidden"
        name="currrentStock[]"
        />
        0
        </td>
        <td>
        <input
        type="hidden"
        name="onSO[]"
        value="0"
        />
        <a
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#ON-PO"
        >
        1000
        </a>
        </td>
        <td>
        <input
        type="hidden"
        name="fstk[]"
        value="0"
        />
        0
        </td>
        <td>
        <a
        href="#"
        value="Remove"
        onClick={() => removeRow(row.id, setTableRows)}
        >
        <i className="fa fa-trash"></i>
        </a>
        </td>
        </tr>
        );
      })
      )}
      </tbody>

      </table>
      </div>
      <div className="purchaseftr">
      <div className="row">
      <div className="col-6 col-lg-3 col-sm-3">
      <p className="frtbx">
      {" "}
      Std Goods :{" "}
      <span id="std_goods">{stdGoods.toFixed(2)}</span>
      </p>
      </div>
      <div className="col-6 col-lg-3 col-sm-3">
      <p className="frtbx">
      {" "}
      VAT Amount :{" "}
      <span id="vat_amt">{vatAmt.toFixed(2)}</span>
      </p>
      </div>
      <div className="col-6 col-lg-3 col-sm-3">
      <p className="frtbx">
      {" "}
      Zero Goods :{" "}
      <span id="zero_goods">
      {zeroGoods.toFixed(2)}
      </span>
      </p>
      </div>
      <div className="col-6 col-lg-3 col-sm-3">
      <p className="frtbx last">
      {" "}
      Grand Total :{" "}
      <span id="grand_total">
      {grandTotal.toFixed(2)}
      </span>
      </p>
      </div>
      </div>
      </div>


      <div className="purchaseform">
      <div className="row mb-3 padd-l-13px">
      <div className="col-6 col-lg-6 col-sm-6">
      <div className="row mb-3">
      <div className="col-3 col-lg-3 col-sm-3">
      <label
      htmlFor="desc"
      className="col-form-label"
      >
      Date of Arrival 
      </label>
      </div>

      <div className="col-3 col-lg-3 col-sm-3">
      <input
      type="date"
      className="form-control"
      name="arrival_date"
      id="arrival_date"
      />
      </div>

      <div className="col-3 col-lg-3 col-sm-3">
      <label
      htmlFor="desc"
      className="col-form-label"
      >
      Job Ref 
      </label>
      </div>

      <div className="col-3 col-lg-3 col-sm-3">
      <input
      type="text"
      className="form-control"
      name="job_ref"
      id="job_ref"
      />
      </div>
      </div>





      <div className="row mb-3">
      <div className="col-6 col-lg-3 col-sm-3">
      <label
      htmlFor="desc"
      className="col-form-label"
      >
      Notes 
      </label>
      </div>
      <div className="col-6 col-lg-9 col-sm-9">
      <textarea
      className="form-control"
      name="notes"
      id="notes"
      style={{ height: "200px" }}
      ></textarea>
      </div>
      </div>
      </div>
      <div className="col-6 col-lg-6 col-sm-6">
      <div className="row mb-3">
      <div className="col-6 col-lg-3 col-sm-3">
      <label
      htmlFor="desc"
      className="col-form-label"
      >
      Collection Address 
      </label>
      </div>

      <div className="col-6 col-lg-9 col-sm-9">
      <textarea
      className="form-control"
      name="collection_address"
      id="collection_address"
      style={{ height: "100px" }}
      ></textarea>
      </div>
      </div>



      <div className="row mb-3">
      <div className="col-6 col-lg-3 col-sm-3">
      <label
      htmlFor="desc"
      className="col-form-label"
      >
      Delivery Address *
      </label>
      </div>
      <div className="col-6 col-lg-9 col-sm-9">
      <select
      name="delivery_id"
      id="delivery_id"
      onChange={(event) =>
        changeWarehouseAddress(
        event,
        setDeliveryAddress,
        warehouseDeliveryAddress
        )
      }
      >
      <option>Select Address</option>
      {warehouseDeliveryAddress.map(
        (item, index) => (
          <option key={index} value={item.id}>
          {item.name}
          </option>
          )
          )}
          </select>
          </div>
          </div>

          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          Warehouse Name 
          </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input
          type="text"
          className="form-control"
          name="name"
          id="name"
          value={deliveryAddress.name}
          disabled
          />
          </div>

          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          Code 
          </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input
          type="text"
          className="form-control"
          name="code"
          id="code"
          value={deliveryAddress.code}
          disabled
          />
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          Address Line 1 
          </label>
          </div>
          <div className="col-6 col-lg-9 col-sm-9">
          <input
          type="text"
          className="form-control"
          name="addressLine1"
          id="addressLine1"
          value={deliveryAddress.address_line1}
          disabled
          />
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          Address Line 2 
          </label>
          </div>
          <div className="col-6 col-lg-9 col-sm-9">
          <input
          type="text"
          className="form-control"
          name="addressLine2"
          id="addressLine2"
          value={deliveryAddress.address_line2}
          disabled
          />
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          City 
          </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input
          type="text"
          className="form-control"
          name="city"
          id="city"
          value={deliveryAddress.city}
          disabled
          />
          </div>

          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          State 
          </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input
          type="text"
          className="form-control"
          name="state"
          id="state"
          value={deliveryAddress.state}
          disabled
          />
          </div>
          </div>

          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          Country 
          </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input
          type="text"
          className="form-control"
          name="country"
          id="country"
          value={deliveryAddress.country}
          disabled
          />
          </div>

          <div className="col-6 col-lg-3 col-sm-3">
          <label
          htmlFor="desc"
          className="col-form-label"
          >
          Post Code 
          </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input
          type="text"
          className="form-control"
          name="postCode"
          id="postCode"
          value={deliveryAddress.postcode}
          disabled
          />
          </div>
          </div>
          <input
          type="hidden"
          name="std_goods"
          value={stdGoods}
          />
          <input
          type="hidden"
          name="zero_goods"
          value={zeroGoods}
          />
          <input
          type="hidden"
          name="vat_amount"
          value={vatAmt}
          />
          <input
          type="hidden"
          name="grand_total"
          value={grandTotal}
          />
          </div>
          <div className="col-6 col-lg-12 col-sm-12">
          <hr />
          </div>

          <div className="col-6 col-lg-12 col-sm-12">
          <Link
          to="#"
          className="popup-innerbtn-common right"
          onClick={submitPurchaseOrderForm}
          >
          Place Purchase Order
          </Link>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </form>
          <Footer />
          </div>
          );
        };

        export default NewPurchaseOrder;
