import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
// import Datepicker from './DateRangePicker';
// import { DateRangePicker } from 'rsuite';
import { DatePicker } from "rsuite";
// import "rsuite/dist/styles/rsuite-default.css";
// import DateRangePickerComponent from './DateRangePicker';

const Dashboard = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [statisticsData, setStatisticsData] = useState(null); // State to store statistics data

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        if (name === 'startDate') {
            setStartDate(value);
        } else if (name === 'endDate') {
            setEndDate(value);
        }
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const fetchData = async () => {
        // Construct your API endpoint URL
        const apiUrl = `https://dev.ebusso.com/get_dashboard_count`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Assuming you store token in localStorage
                },
                body: JSON.stringify({
                    startDate,
                    endDate,
                    role: selectedRole,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch statistics data');
            }

            const data = await response.json();
            console.log(data);
            setStatisticsData(data); // Store fetched data in state
        } catch (error) {
            console.error('Error fetching statistics data:', error);
            // Handle error state if needed
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data when component mounts or dependencies change
    }, [startDate, endDate, selectedRole]); // Add dependencies if needed

    return (
    <div>
        <Header />
       
        <nav class="breadcrumbs">
        <div class="container-fluid">
            <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
                <a href="#"><i class="fa fa-home"></i> </a> 
                <span class="last-crumb">Dashboard</span> </div>
            </div>
        </div>
        </nav>
        <div className="main-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-12 col-sm-12">
                        <div className="show-order-area dashboard">
                            <div className="dashform">                            
                            </div>
                            <h2>
                                <span>
                                    <i className="fa fa-th"></i> Statistics
                                </span>
                                <div className="dashdate-area">
                                    From : {statisticsData ? statisticsData.from_date : 0} &nbsp; To :  {statisticsData ? statisticsData.to_date : 0}
                                </div>
                            </h2>
                            <div className="col-6 col-lg-12 col-sm-12">
                                <div className="row">
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="info-card sales-card leaf-green">
                                            <div className="card-body">
                                                <a href="#">
                                                    <p> Sales Order </p>
                                                    <b id="sales_order_grand_total">£ {statisticsData ? statisticsData.sales_grand_total : 0}</b>{' '}

                                                    <b id="sales_order_item_tax">VAT £ {statisticsData ? statisticsData.delivered_invoice_item_tax : 0}</b>{' '}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="info-card sales-card tomatored">
                                            <div className="card-body">
                                                <a href="javascript:void(0)">
                                                    <p> Delivered Invoice </p>
                                                    <b id="delivered_invoice_grand_total">£ {statisticsData ? statisticsData.delivered_invoice_grand_total : 0}</b>{' '}
                                                    <b id="delivered_invoice_item_tax">VAT £ {statisticsData ? statisticsData.sales_item_tax : 0}</b>{' '}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="info-card sales-card babyellow">
                                            <div className="card-body">
                                                <a href="#">
                                                    <p> Sage Push Invoice </p>
                                                    <b id="sage_push_invoice_grand_total">£{statisticsData ? statisticsData.sage_push_invoice_grand_total : 0}</b>{' '}
                                                    <b id="sage_push_invoice_item_tax">VAT £ {statisticsData ? statisticsData.sage_push_invoice_item_tax : 0}</b>{' '}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="info-card sales-card beetpink">
                                            <div className="card-body">
                                                <a href="#">
                                                    <p> Pending Sage Push Invoice </p>
                                                    <b id="pending_sage_push_invoice_grand_total">
                                                        £ {statisticsData ? statisticsData.pending_sage_push_invoice_grand_total : 0}
                                                    </b>{' '}
                                                    <b id="pending_sage_push_invoice_item_tax">
                                                        VAT £ {statisticsData ? statisticsData.pending_sage_push_invoice_item_tax : 0}
                                                    </b>{' '}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2>
                                <span>
                                    <i className="fa fa-calender"></i> DAILY MIS
                                </span>
                            </h2>
                            <div className="col-6 col-lg-12 col-sm-12">
                                <div className="row">
                                    <div className="col-2 col-sm-4 col-lg-2">
                                        <a href="#" className="bred white quick-button">
                                            <p className="yellow">
                                                {' '}
                                                New <br /> Sales{' '}
                                            </p>
                                            <b>{statisticsData ? statisticsData.new_sales : 0}</b>{' '}

                                        </a>{' '}
                                    </div>
                                    <div className="col-2 col-sm-4 col-lg-2">
                                        <a href="#" className="blightOrange white quick-button ">
                                            <p className="yellow">
                                                {' '}
                                                Order <br /> Accepted{' '}
                                            </p>
                                            <b>{statisticsData ? statisticsData.sale_accept : 0}</b>{' '}

                                        </a>{' '}
                                    </div>
                                  
                               
                                
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </div>
    );
};

export default Dashboard;
