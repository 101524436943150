// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Common_Files/Login";
import Dashboard from "./components/Common_Files/Dashboard";
//Master Routes
import Products from './components/Master/Products';
import ViewProduct from './components/Master/ViewProduct';
import Supplier from './components/Master/Supplier';
import Customer from './components/Master/Customer';
import WarehouseMaster from './components/Master/WarehouseMaster';
import ProductCategoryMaster from './components/Master/ProductCategoryMaster';
import Brand from './components/Master/Brand';
import UserList from './components/user/UserList';

import ProductBatch from './components/Purchase/ProductBatch';

//Purchase Routes
import NewPurchaseOrder from './components/Purchase/NewPurchaseOrder';
import GRN from './components/Purchase/GRN';
import ConfirmGRN from './components/Purchase/ConfirmGRN';
import PurchaseHistory from './components/Purchase/PurchaseHistory';
import PartialGRN from './components/Purchase/PartialGRN';
import CurrentSalesOrder from './components/Sales/CurrentSalesOrder';
import NewSalesOrder from './components/Sales/NewSalesOrder';
import CreditNote from './components/Sales/CreditNote';
import OrderHistory from './components/Sales/OrderHistory';
import Inventory from "./components/Purchase/Inventory";
import Bank from "./components/Master/Bank";
import NestedPopup from "./components/Master/NestedPopup";


function App() {
  return (
    <Router>
      <Routes>     
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/ProductBatch" element={<ProductBatch />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/ViewProduct" element={<ViewProduct />} />
        <Route path="/Supplier" element={<Supplier />} />
        <Route path="/Customer" element={<Customer />} />
        <Route path="/UserList" element={<UserList />} />
        <Route path="/NewPurchaseOrder" element={<NewPurchaseOrder />} />
        <Route path="/GRN" element={<GRN />} />
        <Route path="/ConfirmGRN/:id" element={<ConfirmGRN />} />
        <Route path="/PartialGRN" element={<PartialGRN />} />
        <Route path="/PurchaseHistory" element={<PurchaseHistory />} />   
        <Route path="/WarehouseMaster" element={<WarehouseMaster />} />
        <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
        <Route path="/Brand" element={<Brand />} />
        <Route path="/CurrentSalesOrder" element={<CurrentSalesOrder />} />
        <Route path="/NewSalesOrder" element={<NewSalesOrder />} />
        <Route path="/CreditNote" element={<CreditNote />} />
        <Route path="/Inventory" element={<Inventory />} />
        <Route path="/WarehouseMaster" element={<WarehouseMaster />} />
        <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
        <Route path="/OrderHistory" element={<OrderHistory />} />
        <Route path="/Bank" element={<Bank />} />
        {/* <Route path="/NestedPopup" element={<NestedPopup />} /> */}

      </Routes>
    </Router>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
