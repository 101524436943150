import React, { useState, useEffect , useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Master/ViewProduct';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';

import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { fetchSupplierData, fetchProductData, removeRow,handleChange1 } from '../../services/NewPurchaseOrderService';
import { fetchGRNProducts, handleChange, handleConfirmGRN, handleInputChange } from '../../services/ConfirnGRNService';

const ConfirmGRN = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [supplierData, setSupplierData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [grnItems, setGRNItems] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const confirmModal = useRef(null);
  
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const { id } = useParams();
  const navigateToPOHistory = useNavigate();

  const [customerOptions, setCustomerOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState('');
  const [picker, setSelectedProduct] = useState('');
  const [product, setProduct] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  
  useEffect(() => {
    fetchSupplierData(setSupplierData);
    fetchProductData(setProductData);
    fetchGRNProducts(id, setGRNItems, setPurchaseData);
      // console.log(grnItems); 
    }, []);

    // Calculate grandTotal whenever tableRows changes
    useEffect(() => {
      const newGrandTotal = grnItems.reduce((total, row) => {
        const price = parseFloat(row.price) || 0;
        const received_qty = parseFloat(row.received_qty) || 0;
        return total + (price * received_qty);
      }, 0);
      
      setGrandTotal(newGrandTotal);

      const totalVat = grnItems.reduce((vat, row) => {
       vat = vat + parseFloat(row.vat) || 0;
       return vat;
     },0)
      
      setVatAmt(totalVat);

      const stdGoods = grnItems.reduce((total, row) => {
        if (parseFloat(row.vat) > 0) {
          const price = parseFloat(row.price) || 0;
          const received_qty = parseFloat(row.received_qty) || 0;
          return total + (price * received_qty);
        }
        return total;
      }, 0);
      
      setStdGoods(stdGoods);
      
      
      const zeroGoods = grnItems.reduce((total, row) => {
        if (parseFloat(row.vat) === 0) {
          const price = row.price;
          const received_qty = parseFloat(row.received_qty) || 0;
          return total + (price * received_qty);
        }
        return total;
      }, 0);
      setZeroGoods(zeroGoods);
      
    }, [grnItems]);

    const addExpiryField = (rowIndex) => {
      const newGRNItems = [...grnItems];
      newGRNItems[rowIndex].expiry_dates.push("");
      setGRNItems(newGRNItems);
    };

    const handleProductChange = (inputValue) => {
      setSelectedProduct(inputValue);
    };

    const handleChange_confirm_grn = (selectedOption) => {

      if (selectedOption && selectedOption.value) 
      {
        setProductInputValue(selectedOption);   
        handleChange(selectedOption.value, grnItems, setGRNItems);

        console.log(grnItems);
      } else 
      {
       setProductInputValue(null);   
       console.warn("Selected option is invalid or missing a value");
     }
   };
   
   const addExpiryData = (itemId) => {
    setGRNItems(prevItems =>
      prevItems.map(item => 
        item.id === itemId 
        ? { 
          ...item, 
          expiryData: [...item.expiryData, { expiryDate: "", batchQty: "" }] 
        } 
        : item
        )
      );
  };


  const handleExpiryChange = (e, rowIndex, expiryIndex) => {
    const newGRNItems = [...grnItems];
    newGRNItems[rowIndex].expiry_dates[expiryIndex] = e.target.value;
    setGRNItems(newGRNItems);
  };
  
  const handleExpiryDataChange = (e, itemId, index, field) => {
    const newValue = e.target.value;
    
    setGRNItems(prevItems => prevItems.map(item => 
      item.id === itemId 
      ? { 
        ...item, 
        expiryData: item.expiryData.map((data, i) => 
          i === index ? { ...data, [field]: newValue } : data
          ) 
      } 
      : item
      ));
  };

  const removeExpiryData = (itemId, index) => {
    setGRNItems(prevItems =>
      prevItems.map(item => 
        item.id === itemId 
        ? { 
          ...item, 
          expiryData: item.expiryData.filter((_, i) => i !== index) 
        } 
        : item
        )
      );
  };

  return (
    <div>
    <div className="modal fade" id="crn-add-product" tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">&nbsp;</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body">
    <div className="partialbox">
    <div className="row mb-3">
    <label  htmlFor="inputText" className="col-sm-6 col-form-label">Search (Code/Name/Unit Barcode)</label>
    <div className="col-sm-6">
    <input type="text" className="search-area" placeholder="Search" autoComplete="off" value="" />
    </div>
    </div>
    <table className="table table-bordered dataTable">
    <thead>
    <tr>
    <th>Code</th>
    <th>Name</th>
    <th>PK</th>
    <th>Size</th>
    <th>Unit Barcode</th>
    <th>Stock</th>
    <th>F.STK</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    </tbody>
    </table>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className="modal fade" id="savepartial" tabIndex="-1" ref={confirmModal} >
    <div className="modal-dialog modal-md">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">&nbsp;</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body">
    <div className="add-product-popup">
    <h3>Save Partial GRN?</h3>
    <hr />
    <a href="#" onClick={(e) => handleConfirmGRN(e, 'partial', confirmModal,navigateToPOHistory)} className="popup-innerbtn-common">Save Partial</a> 
    <a href="#" onClick={(e) => handleConfirmGRN(e, 'close', confirmModal,navigateToPOHistory)} className="popup-innerbtn-closebtn">Close GRN</a> 
    </div>
    </div>
    </div>
    </div>
    </div>

    <Header />
    <ViewProduct />
    <div className="breadcrumbs">
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <a href="/GRN">GRN</a> &gt; <span className="last-crumb">Confirm GRN</span> </div>
    </div>
    </div>
    </div>
    <div className="main-container">
    <form id='confirm_grn'>
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12">
    <div className="dashboard">
    <div className="">
    <div className="dashform">
    <div className="topsearch-area">
    <div className="row">
    <div className="col-12 col-lg-3 col-sm-3">
    <div className="row">
    <label
    for="inputText"
      className="col-sm-4 col-form-label"
    >
    Supplier:
    </label>
    <div className="col-sm-8">
    <select
    name="supplier_id"
    id='supplier_id'
    className="form-select orgselect"
    required="">
    <option value="">Select Supplier</option>
    {supplierData.map((item, index) => (
      <option key={index} value={item.id}>{item.name}</option>
      ))}
      </select>

    </div>
    </div>
    </div>
    <div className="col-12 col-lg-3 col-sm-3">
    <div className="row">
    <label
    for="inputText"
      className="col-sm-4 col-form-label"
    >
    Product:
    </label>
    <div className="col-sm-8">
    <Select
    isClearable
    onInputChange={handleProductChange}
    onChange={handleChange_confirm_grn}
    options={productData}
    placeholder="Select Product"
    value={productInputValue}
    />
    </div>
    </div>  
    </div>
    </div>
    </div>
    </div>


    <div className="verical-scrl-wrapper common-table confirm-grn-tbl" id="style-3">
    <table className="table table-bordered dataTable" id="dsTable">
    <thead>
    <tr>
    <th>Code</th>
    <th>Expiry</th>
    <th>Description</th>
    <th>Pk</th>
    <th>Size</th>
    <th>Order Qty</th>
    <th>Receive Qty</th>
    <th>CurStock</th>
    <th>On S.O</th>
    <th>FStk</th>
    <th>Action</th>
    </tr>
    </thead>
    <tbody>
    {grnItems.map((row, rowIndex) => (
      <tr key={row.id}>
      <td>
      <input type="hidden" name="product_id[]" value={row.product_id} />
      <Link to="#" data-bs-toggle="modal" data-bs-target="#viw-pst-invs">{row.code}</Link>
      </td>
      <td>
      {row.expiryData.map((data, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <input
        type="date"
        className="form-control expiryDate"
        name={`expiry_${row.product_id}[]`}
        value={data.expiryDate}
        onChange={(e) => handleExpiryDataChange(e, row.id, index, 'expiryDate')}
        style={{ marginRight: '5px' }}
        />
        <input
        type="text"
        className="form-control batchQty qtyinputbox"
        name={`batch_qty_${row.product_id}[]`}
        placeholder="Batch Qty"
        value={data.batchQty}
        onChange={(e) => handleExpiryDataChange(e, row.id, index, 'batchQty')}
        style={{ marginRight: '5px' }}
        />
        {index != 0 && (
          <button 
          type="button" 
          onClick={() => removeExpiryData(row.id, index)} 
          style={{ marginRight: '5px', color: 'red', cursor: 'pointer' }}
          >
          &#10005;
          </button>

          )}
          <div className="button_for_addmore">
          <button type="button" onClick={() => addExpiryData(row.id)}>+</button>
          </div>
          </div>
          ))}

          </td>


          <td>
            <p className="cut-text">
              <a
              data-bs-toggle="modal"
              className="applybtn"
              data-id={row.product_id}
              data-bs-target="#viewproduct"
              >
              {row.product_desc}
            </a>
            </p>
          </td>

          <td> {row.pack}</td>
          <td> {row.size}</td>
          <td><input type="hidden" name="order_qty[]" value={row.order_qty} /> {row.order_qty} </td>
          <td><input type="text"
          name="received_qty[]" 
          className="form-control qtyinputbox"
          onChange={(e) => handleInputChange(e, row.id, "received_qty", setGRNItems, grnItems)}
          /></td>
          <td><input type="hidden" name="currrent_stock[]" value={row.current_stock} />{row.current_stock}</td>
          <td><input type="hidden" name="onSO[]" value="0" />0</td>
          <td><input type="hidden" name="fstk[]" value="0" />0</td>
          <td>
          <a href="#" value="Remove" onClick={() => removeRow(row.id, setGRNItems)}>
          <i className="fa fa-trash"></i>
          </a>
          </td>
          </tr>
          ))}
          </tbody>
          </table>
          </div>
          <div className="purchaseftr">
          <div className="row">
          <div className="col-6 col-lg-3 col-sm-3">Std Goods : {stdGoods.toFixed(2)}</div>
          <div className="col-6 col-lg-3 col-sm-3">VAT Amount : {vatAmt.toFixed(2)}</div>
          <div className="col-6 col-lg-3 col-sm-3">Zero Goods : {zeroGoods.toFixed(2)}</div>
          <div className="col-6 col-lg-3 col-sm-3">Grand Total : {grandTotal.toFixed(2)}</div>
          </div>
          </div>
          <div className="purchaseform">
          <input type="hidden" name="stdGoods" value={stdGoods} />
          <input type="hidden" name="vatAmt" value={vatAmt} />
          <input type="hidden" name="zeroGoods" value={zeroGoods} />
          <input type="hidden" name="grandTotal" value={grandTotal} />
          <input type="hidden" name="warehouse_id" id='warehouse_id'  />
          <input type="hidden" name="purchase_id" id='purchase_id'  />


          <div className="row">
          <div className="col-6 col-lg-6 col-sm-6">



          <div className="row mb-3">
          <div className="col-3 col-lg-3 col-sm-3">
             <label  htmlFor="desc" className="col-form-label">Date of Arrival </label>
          </div>
          <div className="col-3 col-lg-3 col-sm-3">
             <input type="date" className="form-control" name="arrival_date" id="arrival_date"  />
          </div>
          <div className="col-3 col-lg-3 col-sm-3">
            <label  htmlFor="desc" className="col-form-label">Job Ref *</label>
          </div>
            <div className="col-3 col-lg-3 col-sm-3">
           <input type="text" className="form-control" name="job_ref" id="job_ref" />
          </div>
          </div>

          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label  htmlFor="desc" className="col-form-label">Notes </label>
          </div>
          <div className="col-6 col-lg-9 col-sm-9">
          <textarea className="form-control" name="notes" id="notes"></textarea>
          </div>
          </div>
          </div>
          <div className="col-6 col-lg-6 col-sm-6">
          <div className="row mb-3">
          <div className="col-12 col-lg-12 col-sm-12">
          <label  htmlFor="desc" className="col-form-label">Delivery Address (of Warehouse)</label>
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-3 col-lg-3 col-sm-3">
          <label  htmlFor="desc" className="col-form-label">Warehouse Name</label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input type="text" className="form-control"  id="warehouse_name" />
          </div>
          <div className="col-6 col-lg-2 col-sm-2">
          <label  htmlFor="desc" className="col-form-label">Code:</label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input type="text" className="form-control"  id="wcode" />
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label  htmlFor="desc" className="col-form-label">Address Line 1 </label>
          </div>
          <div className="col-8 col-lg-8 col-sm-8">
          <input type="text" className="form-control"  id="addr1" />
          </div>
          </div>
          <div div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label  htmlFor="desc" className="col-form-label">Address Line 2 </label>
          </div>
          <div className="col-8 col-lg-8 col-sm-8">
          <input type="text" className="form-control"  id="addr2" />
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label  htmlFor="desc" className="col-form-label">City </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input type="text" className="form-control"  id="city" />
          </div>
          <div className="col-6 col-lg-2 col-sm-2">
          <label  htmlFor="desc" className="col-form-label">State </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input type="text" className="form-control"  id="state" />
          </div>
          </div>
          <div className="row mb-3">
          <div className="col-6 col-lg-3 col-sm-3">
          <label  htmlFor="desc" className="col-form-label">Country </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input type="text" className="form-control"  id="country" />
          </div>
          <div className="col-6 col-lg-2 col-sm-2">
          <label  htmlFor="desc" className="col-form-label">Post Code </label>
          </div>
          <div className="col-6 col-lg-3 col-sm-3">
          <input type="text" className="form-control"  id="postcode" />
          </div>
          </div>
          </div>
          <div className="col-6 col-lg-12 col-sm-12">
          <hr />
          </div>
          <div className="col-6 col-lg-12 col-sm-12"><a href="#" className="popup-innerbtn-common right" data-bs-toggle="modal" data-bs-target="#savepartial">Confrm GRN</a></div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </form>
          </div>
          <Footer />
          </div>
          );
        };

        export default ConfirmGRN; 
