import React, { useState, useEffect } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";

const OrderHistory = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [orderHistory, setorderHistory] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("searchByFromdate", fromDate);
    formData.append("searchByTodate", toDate);
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("role", selectedRole);

    const apiUrl = `https://apidev.ebusso.com/order/history`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch new sales data");
      }

      const data = await response.json();
      setorderHistory(data.data);
      $("#total_grand_total").text(data.total_grand_total);
      $("#total_grand_total1").text(data.total_grand_total1);
    } catch (error) {
      console.error("Error fetching new sales data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: orderHistory,
      columns: [
        { data: "date" },
        {
          data: 'reference_no',
          render: function (data) {
            return `<a href="#">${data}</a>`;
          },
        },
        {
          data: "customer",
          render: function (data) {
            return `<p className="cut-text">${data}</p>`;
          },
        },
        { data: "grand_total" },
        { data: "grand_total1" },
        { data: "previous_due" },
        { data: "grand_total" },
        { data: "route_number" },
        { data: "sale_status" },
      ],
    });
    return () => {
      table.destroy();
    };
  }, [orderHistory]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="dashboard.html">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Order History</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="dashform">
                <div class="topsearch-area"> 
                  <div class="row">
                    <div class="col-12 col-lg-8 col-sm-8">

                    <div style={{ marginRight: "10px" }}>
                      <label>From Date</label>
                      <input
                        type="date"
                        name="fromDate"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="form-control"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <label>To Date</label>
                      <input
                        type="date"
                        name="toDate"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className="form-control"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                    <div style={{ marginRight: "100px" }}>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="selectdate-btn"
                      >
                        Submit
                      </button>
                    </div>
                    </div>


                    <div class="col-12 col-lg-4 col-sm-4">
                      <div class="row">
                    <div class="col-12 col-lg-6 col-sm-6" >
                      <div class="current-total-area">
                      <b>
                        <label htmlFor="total_grand_total">Order Grand Total</label>
                      </b>
                      <div id="total_grand_total" class="total"></div>
                      </div>

                    </div>


                    <div class="col-12 col-lg-6 col-sm-6">
                    <div class="current-total-area">
                      <label htmlFor="total_grand_total1">
                        Current Grand Total
                      </label>
                      <div id="total_grand_total1" class="total"></div>

</div>
                    </div>



                    </div>

</div>


                  </div>
</div>

                </div>

                <div>
                  <div
                    className="verical-scrl-wrapper order-history-table"
                    id="style-3"
                  >
                    <table
                      className="table table-bordered dataTable"
                      id="neworderTable"
                    >
                      <thead>
                        <tr>
                          <th>Order date</th>
                          <th>Order Id</th>
                          <th>Customers ID</th>
                          <th>Order Amount</th>
                          {/* <th>Current Amount</th> */}
                          <th>Previous Dues</th>
                          {/* <th>Total Due</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderHistory.map((item, index) => (
                          <tr key={index}></tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderHistory;
