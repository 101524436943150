import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from '../Common_Files/Config';
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

const ViewProduct = () => {
    const [draw, setDraw] = useState(1);
    const [start, setStart] = useState(0);
    const [length, setLength] = useState(50);
    const [productData, setProductData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [editProduct, setEditProduct] = useState([]); 
    const [vatData, setVatData] = useState([]); 
    const [warehouse, setWarehouse] = useState([]);
    const modalRef = useRef(null);
    const updateModalRef = useRef(null); 
    const tableRef = useRef(null); 
    const accessToken = localStorage.getItem('accessToken');


const fetchData = async () => {
  const searchValue = $('#product_name').val();
  const location_name = $('#location_name').val();
  const warehouse_id = $('#warehouse_id').val();
  const stock_type = $('#stock_type').val();

  const formData = new FormData();
  formData.append("draw", draw);
  formData.append("start", start);
  formData.append("length", length);
  formData.append("searchValue", searchValue);
  formData.append("location_name", location_name);
  formData.append("warehouse_id", warehouse_id);
  formData.append("stock_type", stock_type);


};

useEffect(() => {
  fetchData();
}, []);


useEffect(() => {
    $('#dsTable tbody').off('click', '.applybtn');
    $('#dsTable tbody').on('click', '.applybtn', function () {

      const product_id = $(this).data('id');
      const formData = new FormData();
      formData.append('id', product_id);
      document.getElementById('viewProductForm').reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
          },
          body: formData,
      });

          const data = await response.json();
            // $('#code').val(data.products[0].product_desc);
            $('#packValue').val(data.products[0].pack);
            $('#vat').val(data.products[0].vat);
            $('#weight').val(data.products[0].weight);
            $('#supplier').val(data.products[0].supplier_name);
            $('#description').val(data.products[0].product_desc);
            $('#sizeValue').val(data.products[0].size);
            $('#barcode').val(data.products[0].case_barcode);
            $('#pallet_qty').val(data.products[0].pallet_qty);
            $('#case_price').val(data.products[0].price);
            $("#product_status").html(data.products[0].status 
                  ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>' 
                  : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
              );
        } catch (error) {
            console.error("Error fetching product data:", error);
        }
    };
    fetchProductData();
});

    return () => {
      $('#productListTable tbody').off('click', '.applybtn');
  };
}, []);






return (
    <div>  
    <div className="modal fade" id="viewproduct" tabIndex="-1">
    <div className="modal-dialog modal-xl">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">View Product</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body">
    <div className="viewcustomer-area">
    <form id="viewProductForm">

    <div className="row">
    <div className="col-6 col-lg-6 col-sm-6">&nbsp;</div>
    <div className="col-6 col-lg-6 col-sm-6">
    <div className="row">
    <label htmlFor="inputText" className="col-sm-6" >&nbsp;</label>
    <div className="col-sm-6">                      
    <h4 id="product_status"></h4>
    </div>
    </div>
    </div>

    </div>
    <hr className="devider"/>
    <div className="row">
    <div className="col-6 col-lg-6 col-sm-6">
    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Code</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="code" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Pack</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="packValue" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">VAT</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" name="vat" id="vat" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Weight</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="weight" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Supplier</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" name="supplier" id="supplier" disabled />
    </div>
    </div>

    </div>
    <div className="col-6 col-lg-6 col-sm-6">
    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Description</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="description" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Size</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="sizeValue" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Barcode</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="barcode" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Pallet Qty</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="pallet_qty" disabled />
    </div>
    </div>

    <div className="row mb-3">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">Case Price</label>
    <div className="col-sm-6">
    <input type="text" className="form-control" id="case_price" disabled />
    </div>
    </div>
    </div>
    </div>
    </form>
    <div className="viewcustomer-tbl-area">
    <h5>Stock</h5>
    <table className="table table-bordered">
    <thead>
    <tr>
    <th>&nbsp;</th>
    <th>Free</th>
    <th>Stock</th>
    <th>On S.O</th>
    <th>Alloc</th>
    <th>On PF</th>
    <th>On PO</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>Qty</td>
    <td><a href="#" target="_blank">-324</a></td>
    <td>0</td>
    <td><a href="#" data-bs-toggle="modal"data-bs-target="#viewproduct-tbl1">324</a></td>
    <td>0</td>
    <td><a href="#" data-bs-toggle="modal" data-bs-target="#viewproduct-tbl2">0</a></td>
    <td><a href="#" data-bs-toggle="modal" data-bs-target="#viewproduct-tbl3">7800</a></td>

    </tr>
    </tbody>
    </table>
    </div>

    <div className="viewcustomer-tbl-area">
    <h5>Last 12 Month Turnover</h5>

    <table className="table table-bordered">
    <thead>
    <tr>
    <th>&nbsp;</th>
    <th>Aug</th>
    <th>Sep</th>
    <th>Oct</th>
    <th>Nov</th>
    <th>Dec</th>
    <th>Jan</th>
    <th>Feb</th>
    <th>Mar</th>
    <th>Apr</th>
    <th>May</th>
    <th>Jun</th>
    <th>Jul</th>
    </tr>
    </thead>

    <tbody>
    <tr>
    <td>T/O</td>
    <td>13010</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    <td>3244</td>
    <td>0</td>
    <td>2343</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    </tr>
    </tbody>
    </table>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    );
};

export default ViewProduct;
