import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import Swal from 'sweetalert2';

const Brand = () => {
    const [searchValue, setSearchValue] = useState([]);
    const modalRef = useRef(null);
    const editModalRef = useRef(null);    
    const tableRef = useRef(null); // Reference to the table element

    const initializeDataTable = () => {
      const $table = $(tableRef.current);
      const searchValue = $('#searchValue').val();
      // Destroy existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      // Initialize DataTable with server-side processing
      $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
              url: `${config.api_url}/ws-get-brand-list`,
              type: 'POST',
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                  return json.data;
              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },
          columns: [
              { data: null,
                width: "7%",
                render:function(data, type, row){
                  return ++i;
                }
               },
              { data: 'name',  },
              { data: 'status', width: "7%" },
              {
                data: null,
                title: 'Action',
                width: "7%",
                render: (data, type, row) => `
                    <a href="#" class="editbtn" title="Edit Brand" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#edit_brand">
                        <i class="fa fa-edit"></i> 
                    </a>`
              }
          ],
          columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
        
          pageLength: 50,
      });
  

      // Event delegation for edit and view actions
      $table.on('click', '.editbtn', function() {
          const id = $(this).data('id');
          handleEditClick(id);
      });
  
  };
  
  useEffect(() => {
      initializeDataTable();  
      return () => {
          if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
              $(tableRef.current).DataTable().destroy();
          }
      };
  }, []);

  const handleSearchBrand = (event) =>{
      setSearchValue(event.target.value);
  }

      const handleAddBrand = async (e) => {
        e.preventDefault();
        try {
          const formElement = document.getElementById("addNewBrand");
          const formData = new FormData(formElement);
          formData.set("status", formData.get("status") === "on" ? 1 : 0);
    
          const response = await axios.post(
            `${config.api_url}/ws-add-brand`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
    
          if (response.data.success) {
            Swal.fire({
              title: "Success!",
              text: "Brand added successfully.",
              icon: "success",
              timer: 1500, // Automatically close after 1500ms
              showConfirmButton: false,
            })
              .then(() => {
                // Hide the modal after SweetAlert completes
                if (modalRef.current) {
                  const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
                  if (bsModal) bsModal.hide();
                }
    
                initializeDataTable();
                document.getElementById("addNewBrand").reset();
              })
              .catch((error) => {
                console.error("Error refreshing brand:", error);
              });
          } else {
            Swal.fire({
              title: "Error!",
              text: response.data.message || "An error occurred while adding a brand.",
              icon: "error",
              timer: 1500, // Automatically close after 1500ms
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error("Request Error:", error);
          Swal.fire({
            title: "Error!",
            text: "An error occurred while adding a brand.",
            icon: "error",
            timer: 1500, // Automatically close after 1500ms
            showConfirmButton: false,
          });
        }
      };

      const handleEditBrand = async (e) => { 
        e.preventDefault();
        try {
          const formElement = document.getElementById("editBrandForm");
          const formData = new FormData(formElement);
          formData.set("status", formData.get("status") === "on" ? 1 : 0);
    
          const response = await axios.post(
            `${config.api_url}/ws-update-brand`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.data.success) {
            Swal.fire({
              title: "Success!",
              text: "Brand updated successfully.",
              icon: "success",
              timer: 1500, 
              showConfirmButton: false,
            })
              .then(() => {
                if (editModalRef.current) {
                  const bsModal = window.bootstrap.Modal.getInstance(editModalRef.current);
                  if (bsModal) bsModal.hide();
                }
                initializeDataTable();
                document.getElementById("editBrandForm").reset();
              })
              .catch((error) => {
                console.error("Error refreshing brand:", error);
              });
          } else {
            Swal.fire({
              title: "Error!",
              text: "An error occurred while updating a brand.",
              icon: "error",
              timer: 1500, // Automatically close after 1500ms
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error("Request Error:", error);
          Swal.fire({
            title: "Error!",
            text: "An error occurred while updating a brand.",
            icon: "error",
            timer: 1500, // Automatically close after 1500ms
            showConfirmButton: false,
          });
        }
      };

      const handleEditClick = async (id) => {
            document.getElementById("editBrandForm").reset();
            const  formData = new FormData();
            formData.set('brand_id', id);
              const response = await axios.post(`${config.api_url}/ws-get-brand-by-id`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                    }
              });            

              if (response.data.brands[0]) 
                {
                $('#edit_name').val(response.data.brands[0].name);
                $('#brand_id').val(response.data.brands[0].id);
                (response.data.brands[0].status == "1") ? $('#status').prop('checked', true) : $('#status').prop('checked', false);
              }
      };

    

    return (
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span className="last-crumb">Brand</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className='add-categy-tbl'>
                            <div class="topsearch-area">
                                <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                <div class="row">
                                  <label for="inputText" class="col-sm-3 col-form-label">Brand:</label>
                                  <div class="col-sm-6">
                                  <input type="text" className="form-control" name="searchValue" id="searchValue" onChange={handleSearchBrand} placeholder="Search Brand" />
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{ float: "right" }}>
                                <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_brand">Add New Brand</a>
                                </div>
                              </div>
                            </div> 
                          </div>


                                <div style={{ width: '100%', float: 'left' }}>
                                    <div className="verical-scrl-wrapper" id="style-3">
                                        <table className="table table-bordered dataTable" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Brand Modal */}
            <div className="modal fade" id="add_brand" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New Brand</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                </div>
                <div className="modal-body">
                  <div className="warehousemaster-area">
                    <form id="addNewBrand">
                      <div class="row mb-3 padd-l-13px">
                        <label for="desc" class="col-sm-6 col-form-label">
                          Brand Name *
                        </label>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" name="name" id="name" />
                        </div>
                      </div>
                      <div class="row mb-3 padd-l-13px">
                        <label for="displayName" class="col-sm-6 col-form-label">Status</label>
                        <div class="col-sm-6">
                          <label class="switch">
                            <input class="switch-input" type="checkbox" name="status" defaultChecked />
                            <span  class="switch-label" data-on="Active"  data-off="Inactive" ></span>{" "}
                            <span class="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <hr />
                      <div class="row padd-l-13px">
                        <div class="col-sm-12">
                          <button
                            type="submit"
                            id="submit"
                            onClick={handleAddBrand}
                            class="popup-innerbtn-common right">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            </div>

            {/* Edit Brand Modal */}
            {/*  */}
            <div className="modal fade" id="edit_brand" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={editModalRef}>
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Brand</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="warehousemaster-area">
                      <form id="editBrandForm">
                        <div className="row mb-3 padd-l-13px">
                          <label for="desc" className="col-sm-6 col-form-label">
                            Brand Name *
                          </label>
                          <div className="col-sm-6">
                            <input type="hidden" name="brand_id" id="brand_id" />
                            <input type="text" className="form-control" name="name" id="edit_name" />
                          </div>
                        </div>
                        <div className="row mb-3 padd-l-13px">
                          <label for="displayName" className="col-sm-6 col-form-label">Status</label>
                          <div className="col-sm-6">
                            <label className="switch">
                              <input className="switch-input" type="checkbox" name="status" id="status" />
                              <span className="switch-label" data-on="Active" data-off="Inactive" ></span>{" "}
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                        <hr />
                        <div className="row padd-l-13px">
                          <div className="col-sm-12">
                            <button type="submit" id="submit" onClick={handleEditBrand} className="popup-innerbtn-common right">Update</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
        </div>
    );
};

export default Brand;
