import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
  
const accessToken = localStorage.getItem('accessToken');

export const fetchGRNProducts = async (purchase_id, setGRNItems, setPurchaseData) => {
    $('#purchase_id').val(purchase_id);
    const apiUrl = `${config.api_url}/ws-purchase-item-by-id`;
    let formData = new FormData();
    formData.append('purchase_id', purchase_id);

    try {
        const response = await axios.post(apiUrl, formData, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const purchase_item = response.data.purchase_item;

        const initializedGRNItems = purchase_item.map(item => ({
            ...item,
            expiryData: [{ expiryDate: "", batchQty: "" }],
            received_qty: 0,
        }));

        // console.log(initializedGRNItems);
        
        setGRNItems(initializedGRNItems);
        
        const purchase = response.data.purchase;
        setPurchaseData(purchase);
        $('#arrival_date').val(purchase.arrival_date);
        $('#job_ref').val(purchase.job_ref);
        $('#notes').val(purchase.notes);
        $('#warehouse_name').val(purchase.warehouse_name);
        $('#wcode').val(purchase.code);
        $('#addr1').val(purchase.address_line1);
        $('#addr2').val(purchase.address_line2);
        $('#city').val(purchase.city);
        $('#state').val(purchase.state);
        $('#country').val(purchase.country);
        $('#postcode').val(purchase.postcode);
        $('#warehouse_id').val(purchase.warhouse_id);
        $('#supplier_id').val(purchase.supplier_id).change();

    } catch (error) {
        console.error("Error fetching warehouse data:", error);
        alert("An error occurred while fetching the data.");
    }
};



export const handleChange = async (selectedOption, grnItems, setGRNItems) => {
    const id = selectedOption;
    const found = grnItems.find(element => element.product_id === id);
    if (found !== undefined) {
        Swal.fire({
            title: 'Error!',
            text: 'Product already exists.',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false
          });   
      $('#productList').val('');
      return;
    }

    $('#productList').val('');
    const formData = new FormData();
    formData.append("id", id);
    const apiUrl = `${config.api_url}/ws-get-products-by-id`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const data = await response.json();
      const newRow = {
        id: data.products[0].id,
        product_desc: data.products[0].product_desc,
        product_id: data.products[0].id,
        code: data.products[0].code,
        pack: data.products[0].pack,
        size: data.products[0].size,
        price: data.products[0].price,
        vat: data.products[0].vat,
        current_stock: data.products[0].price,
        order_qty: 0,
        received_qty: 0,
        expiryData : [
            {
                expiryDate : "",
                batchQty: ""
            }
        ]
      };

      setGRNItems((prevRows) => [...prevRows, newRow]);
      console.log(newRow);
    } catch (error) {
      console.error("Error fetching products data:", error);
    }
  };


  export const handleConfirmGRN = async (e, status, confirmModal,navigateToPOHistory) => {
    e.preventDefault();

    try {
      const formElement = document.getElementById("confirm_grn");
      const formData = new FormData(formElement);
      formData.append('status', status);

      const response = await axios.post(
        `${config.api_url}/ws-confirm-grn`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${accessToken}`
          },
        }
      );

      if(response.data.success){
        if (confirmModal.current) {
          const bsModal = window.bootstrap.Modal.getInstance(confirmModal.current);
          if (bsModal) bsModal.hide();
        }
        navigateToPOHistory('/PurchaseHistory');
      }

    } catch (error) {
      console.error("Request Error:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while confirming grn.",
        icon: "error",
        timer: 1500, // Automatically close after 1500ms
        showConfirmButton: false,
      });
    }
  };


  export const handleInputChange = (e, id, field, setGRNItems, grnItems) => {
    // const value = acceptNumber(e.target.value);
    const value = e.target.value;

    setGRNItems((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { 
            ...row, 
            [field]: value
          } : row
        )
      );
      console.log(grnItems); 
  };

  export const calculateValue = (quantity, price) => {
    return quantity && price ? quantity * price : 0;
  };

  export const acceptNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };