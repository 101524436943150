import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const PurchaseHistory = () => {
  const [reportrange, setReportrange] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, 'yyyy-MM-dd'));
      setToDate(format(end, 'yyyy-MM-dd'));
      setReportrange(`${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`);
    }
  };

  const fetchData = async () => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      window.location.href = '/login';
      return;
    }

    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('searchValue', selectedRole);

    const apiUrl = `${config.api_url}/ws-purchase-history-list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch purchase data');
      }

      const data = await response.json();
      setCompleteTrips(data.data);
    } catch (error) {
      console.error('Error fetching purchase data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#dsTable').DataTable({
      data: completeTrips,
      columns: [
      
        { data: 'date' },     
        { data: 'reference_no' },
        { data: 'name' }, 
        { data: null, render: (data, type, row) => row.grand_total - row.vat_amount },
        { data: 'vat_amount' },
        { data: 'grand_total' },
        { 
          data: null,
          render: () => '<a href="#"><i class="fa fa-eye"></i></a><a href="#"><i class="fa fa-download"></i></a>',
        }
      ],
      columnDefs: [
        { targets: '_all', sortable: false }
      ],
      paging: true,
      lengthChange: false,
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
 
    });

    return () => {
      table.destroy();
    };
  }, [completeTrips]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <span className="last-crumb">Purchase History</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="dashboard">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-lg-4 col-sm-4">
                      <div className="row mb-3">
                        <label htmlFor="poId" className="col-sm-5 col-form-label">P.O ID/Supplier</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            id="poId"
                            className="search-area"
                            placeholder="Search"
                            autoComplete="off"
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-sm-4">
                      <div className="row mb-3">
                        <label htmlFor="dateRange" className="col-sm-3 col-form-label">Date Range</label>
                        <div className="col-sm-7">
                          <DateRangePicker
                            value={[fromDate ? new Date(fromDate) : undefined, toDate ? new Date(toDate) : undefined]}
                            onChange={handleDateChange}
                            format="MM/dd/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
               
                </form>
                <div className="vertical-scrl-wrapper common-table purchase-history-tbl" id="style-3">
                  <table className="table table-bordered dataTable" id="dsTable">
                    <thead>
                      <tr>

                        <th>Raised on</th>                    
                        <th>P.O ID</th>
                        <th>Supplier(ID)</th>
                        <th>Order Amount</th>
                        <th>Tax</th>
                        <th>Net</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Data will be injected here by DataTables */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PurchaseHistory;
