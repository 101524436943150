import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { render } from '@testing-library/react';

const GRN = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const modalRef = useRef(null);
  const editModalRef = useRef(null);
  const tableRef = useRef(null); 
  const accessToken = localStorage.getItem('accessToken');

  const initializeDataTable = () => {
    const $table = $(tableRef.current);
    const searchValue = $('#searchValue').val();

    // Destroy existing DataTable instance if it exists
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,

      ajax: {
        url: `${config.api_url}/ws-grn-list`,
        type: 'POST',
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue
          });
        },
        dataSrc: function (json) {
          return json.data;
        }
      },
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },

      columns: [
        {
          data: 'arrival_date',
          width: "6%", 
          render: function(data, type, row) {
            const [year, month, day] = row.arrival_date.split('-');
            return `${day}-${month}-${year}`;
          }
        },        
        { data: 'reference_no', },
        { data: 'name', },
        { data: null,
          width: "7%",
          render: function (data, type, row) {
            return parseFloat(row.grand_total);
          }
         },
        { data: 'vat_amount', width: "7%" },
        { data: 'grand_total',  width: "7%" },
        {
          data: 'status',
          width: "7%",
         
        },
        {
          data: null,
          width: "7%",
          render: (data, type, row) => `
              <a href="${`/ConfirmGRN/${row.id}`}" title="Edit"><i class="fa fa-eye"></i></a>
              <a href="#" title="P.O. Collection Note"><i class="fa fa-download"></i></a>`
        }
       ],

      columnDefs: [
        {
          orderable: false,
          targets: '_all'
        }
      ],

      pageLength: 10,
    });

    // Event delegation for edit and view actions
    $table.on('click', '.editbtn', function () {
      const id = $(this).data('id');
      // handleEditClick(id);
    });

    $table.on('click', '.viewbtn', function () {
      const id = $(this).data('id');
      // handleViewClick(id);
    });
  };

  useEffect(() => {
    initializeDataTable();

    return () => {
      if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, []);

  return (
    <div>
      <div  className="modal fade" id="savepartial" tabIndex="-1">
        <div  className="modal-dialog modal-md">
          <div  className="modal-content">
            <div  className="modal-header">
              <h5  className="modal-title">&nbsp;</h5>
              <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div  className="modal-body">
              <div  className="add-product-popup">
                <h3> Save Partial GRN?</h3>
                <hr />
                <a  className="popup-innerbtn-common" href="#"> Save Partial</a>
                <a href="/PurchaseHistory"  className="popup-innerbtn-closebtn">Close GRN</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <div  className="breadcrumbs">
        <div  className="container-fluid">
          <div  className="row">
            <div  className="col-12 col-lg-12 col-sm-12"> <a href="#"><i  className="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <span  className="last-crumb">Goods Receive Notes</span> </div>
          </div>
        </div>
      </div>
      <div  className="main-container">
        <div  className="container-fluid">
          <div  className="row">
            <div  className="col-12 col-lg-12 col-sm-12">
              <div  className="dashboard">
                <div  className="">
                  <div  className="row">
                    <div  className="col-12 col-lg-4 col-sm-4">
                      <div  className="row mb-3">
                        <label htmlFor="inputText"  className="col-sm-5 col-form-label">P.O ID/Supplier</label>
                        <div  className="col-sm-6">
                          <input type="text"  className="search-area" placeholder="Search" autoComplete="off" value="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  className="verical-scrl-wrapper common-table grn-tbl" id="style-3">
                    <table  className="table table-bordered dataTable" id="grnTable" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>P.O ID</th>
                          <th>Supplier(ID)</th>
                          <th>Order Amount</th>
                          <th>Tax</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GRN;
