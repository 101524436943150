import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';
import Swal from "sweetalert2";

const accessToken = localStorage.getItem('accessToken');


    // export const fetchDeliveryAddressData = async (setDeliveryAddresses, setDeliveryAddrDropdown) => {
    //     const apiUrl = `${config.api_url}/ws-get-warehouse`;
    //     try {
    //     const response = await axios.post(apiUrl, {
    //         headers: {
    //         'Authorization': `Bearer ${accessToken}`
    //         },
    //     });
    
    //     if (Array.isArray(response.data.warehouse)) {
    //         const formattedOptions = response.data.warehouse.map(item => ({
    //         label: `${item.name}`,
    //         value: item.id,
    //         }));

    //         setWarehouseData(formattedOptions);
    //     }   
    //     } catch (error) {
    //     console.error("Error fetching warehouse data:", error);
    //     }
    // };

    export const fetchCustomerData = async (setCustomerData) => {
        const apiUrl = `${config.api_url}/ws-get-customer-list`;
        try {
          const response = await axios.post(apiUrl, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          });
      
          if (Array.isArray(response.data.customer)) 
            {
            const formattedOptions = response.data.customer.map(item => ({
              label: `${item.company_name}`,
              value: item.id,
            }));

            setCustomerData(formattedOptions);
          }   
        } catch (error) {
          console.error("Error fetching products data:", error);
        }
      };


      export const fetchWarehouseData = async (setWarehouseData) => {
        const apiUrl = `${config.api_url}/ws-get-warehouse`;
        try {
          const response = await axios.post(apiUrl, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          });
      
          if (Array.isArray(response.data.warehouse)) {
            const formattedOptions = response.data.warehouse.map(item => ({
              label: `${item.name}`,
              value: item.id,
            }));

            setWarehouseData(formattedOptions);
          }   

        } catch (error) {
          console.error("Error fetching warehouse data:", error);
        }
      };
 

    export const handleCustomerChange = async (e, setInvoiceAddress, setDeliveryAddresses,setDeliveryAddrDropdown,deliveryAddrDropdown) => {
        let id = e.value;
        const formData = new FormData();
        formData.set('customer_id', id);
        const response = await axios.post(`${config.api_url}/ws-get-customer-list-by-id`, formData, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
              }
        });

        setDeliveryAddresses(response.data.delivery_addresses);
        
        const formattedOptions = response.data.delivery_addresses.map(item => ({
          label: `${item.address_line1}, ${item.city}, ${item.post_code}`,
          value: item.id, 
        }));

        setDeliveryAddrDropdown(formattedOptions);
        setInvoiceAddress(response.data.customer);
    };

    export const handleProductAdd = async (e, tableRows, setTableRows) => {
      const id = e?.value;
      if (id == null) return;

      const found = tableRows?.find(element => element.id === id);
      if (found) {
        Swal.fire({
          title: "Error!",
          text: "Product already exists",
          icon: "error",
          timer: 1500, 
          showConfirmButton: false,
        })
          return;
        }

      const formData = new FormData();
      formData.append("id", id);
      // API URL
      const apiUrl = `${config.api_url}/ws-get-products-by-id`;
  
      try {
          // Fetch product data
          const response = await axios.post(apiUrl,formData, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            }
          });
  
          // Parse response
          const data = await response.data;
          // Create new row
          const newRow = {
            id: data.products[0].id,
            code: data.products[0].code,
            product_desc: data.products[0].product_desc,
            pack: data.products[0].pack,
            size: data.products[0].size,
            price: data.products[0].price,
            cost: data.products[0].cost,
            quantity: data.products[0].quantity,
            taxrate: data.products[0].rate,
            vat: parseFloat(data.products[0].vat),
            value: 0,
            profit: (data.products[0].price-data.products[0].cost)
          };
  
          // Update table rows
          setTableRows(prevRows => [...prevRows, newRow]);
        } catch (error) {
          console.error("Error fetching products data:", error);
        }
      };

    // Modify this function to use FormData
    // export  const submitSalesOrderForm = async (tableRows) => {
    //   const form = document.getElementById('new_sales_order');
    //   const formData = new FormData(form);
      
    //   // Manually append the table rows data
    //   tableRows.forEach((row, index) => {
    //     formData.append(`code[${index}]`, row.code);
    //     formData.append(`expiryDate[${index}]`, row.expiryDate);
    //     formData.append(`packsize[${index}]`, row.pack);
    //     formData.append(`productSize[${index}]`, row.size);
    //     formData.append(`qty[${index}]`, row.quantity);
    //     formData.append(`price[${index}]`, row.price);
    //     formData.append(`value[${index}]`, row.value);
    //     formData.append(`Disc[${index}]`, row.disc);
    //     formData.append(`NetP[${index}]`, row.netP);
    //     formData.append(`Vat[${index}]`, row.vat);
    //     formData.append(`currrentStock[${index}]`, row.curStock);
    //     formData.append(`onSO[${index}]`, row.onSO);
    //     formData.append(`fstk[${index}]`, row.fstk);
    //   });

    //   try {
    //     const response = await axios.post(`${config.api_url}/ws-sales-add`, formData, {
    //       headers: {
    //         authorization: `Bearer ${accessToken}`,
    //       }
    //     });

    //     const result = response.data;
    //     Swal.fire({
    //       title: "Success!",
    //       text: "Order submitted successfully.",
    //       icon: "success",
    //       timer: 1500, // Automatically close after 1500ms
    //       showConfirmButton: false,
    //     })

    //     // navigateToGRNList('/GRN');
      
    //   } catch (error) {
    //     console.error("Error accepting order:", error);
    //   }
    // };
    
    