import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import config from '../Common_Files/Config';

const ProductBatch = ({ onViewPastPurchase, getProductBatchData, modalData = false }) => {
  const [productBatch, setProductBatch] = useState([]);
  const [productPO, setProductPO] = useState([]);
  const [showPastPurchaseModal, setShowPastPurchaseModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const accessToken = localStorage.getItem('accessToken');

  const handleShowPastPurchaseModal = () => setShowPastPurchaseModal(true);
  const handleClosePastPurchaseModal = () => setShowPastPurchaseModal(false);

  const handleShowInvoiceModal = (event) => {
    event.preventDefault(); // Prevent default anchor behavior

    const productId = event.currentTarget.getAttribute('data-id');
    setSelectedInvoice(productId);
    setShowInvoiceModal(true);
    GetProductPO(productId);
  };

  const handleCloseInvoiceModal = () => setShowInvoiceModal(false);

  const GetProductPO = async (id) => {
    try {
      const formData = new FormData();
      formData.set('product_id', id);

      const response = await axios.post(
        `${config.api_url}/ws-get-product-po`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setProductPO(response.data.purchase);
    } catch (error) {
      console.error("Error fetching product PO data:", error);
    }
  };

  useEffect(() => {
    if (modalData) {
      setProductBatch(modalData);
    }
  }, [modalData]);

  useEffect(() => {
    if (onViewPastPurchase) {
      onViewPastPurchase(handleShowPastPurchaseModal);
    }
  }, [onViewPastPurchase]);

  return (
    <div>
      <div className="modal fade" id="view-past-invs" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">All Batches: {productBatch[0]?.reference_no} ({productBatch[0]?.reference_no})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Table striped bordered hover className="dataTable">
                <thead>
                  <tr>
                    <th>Expiry</th>
                    <th>Stock Qty</th>
                    <th>Sold Qty</th>
                    <th>Bal Qty</th>
                    <th>Alloc</th>
                    <th>UnAlloc</th>
                    <th>PO No.</th>
                    <th>Net Price</th>
                  </tr>
                </thead>
                <tbody>
                  {productBatch.map((item, index) => (
                    <tr key={index}>
                      <td>{item.expiry_date}</td>
                      <td>{item.qty}</td>
                      <td>0</td>
                      <td>{item.qty}</td>
                      <td>0</td>
                      <td>0</td>
                      <td>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          {item.reference_no}
                        </a>
                      </td>
                      <td>{item.cost_price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showPastPurchaseModal} onHide={handleClosePastPurchaseModal} size="lg">
        <Modal.Header closeButton>
        <h5>All Batches: {productBatch[0]?.product_desc}  ({productBatch[0]?.code})</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="partialbox">
            <h3>
              <a href="#" data-id={productBatch[0]?.product_id} onClick={handleShowInvoiceModal}>
                View Past Purchase
              </a>
            </h3>

            <Table striped bordered hover className="dataTable">
              <thead>
                <tr>
                  <th>Expiry</th>
                  <th>Stock Qty</th>
                  <th>Sold Qty</th>
                  <th>Bal Qty</th>
                  <th>Alloc</th>
                  <th>UnAlloc</th>
                  <th>PO No.</th>
                  <th>Net Price</th>
                </tr>
              </thead>
              <tbody>
                {productBatch.map((item, index) => (
                  <tr key={index}>
                    <td>{item.expiry_date}</td>
                    <td>{item.qty}</td>
                    <td>0</td>
                    <td>{item.qty}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        {item.reference_no}
                      </a>
                    </td>
                    <td>{item.cost_price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* Add footer content if needed */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInvoiceModal}
        onHide={handleCloseInvoiceModal}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
           <h5>All Batches: {productBatch[0]?.product_desc}  ({productBatch[0]?.code})</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="partialbox">
            <Table striped bordered hover className="dataTable">
              <thead>
                <tr>
                  <th>PO#</th>
                  <th>Qty</th>
                  <th>Cost Price</th>
                  <th>Discount</th>
                  <th>ICDate</th>
                </tr>
              </thead>
              <tbody>
                {productPO.map((item, index) => (
                  <tr key={index}>
                    <td>{item.reference_no}</td>
                    <td>{item.quantity}</td>
                    <td>{item.cost}</td>
                    <td>{item.discount_amount}</td>
                    <td>0</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* Add footer content if needed */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductBatch;
