import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import config from '../Common_Files/Config';
const API_URL = `${config.api_url}/ws-get-suppliers`;

const SupplierSearch = () => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {  
    const fetchOptions = async () => {
      try {
        const response = await axios.get(API_URL);
        const data = response.data.supplier;     
      
        setOptions(data.map(item => ({
          value: item.id,      
          label: item.name    
        })));
      } catch (err) {
        setError('Failed to fetch options');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (selectedOption) => {
    console.log('Selected option:', selectedOption);
  };

  
  return (
    <Select name="supplier_id"
      options={options}
      onChange={handleChange}
      placeholder="Select Supplier"
      isSearchable 
    />
  );
};

export default SupplierSearch;
