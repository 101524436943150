import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';

const accessToken = localStorage.getItem('accessToken');

export const fetchSupplierData = async (setSupplierData) => {
  const apiUrl = `${config.api_url}/ws-get-suppliers`;
  
  try {
    const response = await axios.post(apiUrl, null, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    
    const data = response.data;
    setSupplierData(data.supplier); 
  } catch (error) {
    console.error("Error fetching suppliers data:", error);
  }
};

export const fetchWarehouseDeliveryData = async (setWarehouseDeliveryAddress, accessToken) => {
  const apiUrl = `${config.api_url}/ws-get-warehouse`;
  
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }); 
    
    const data = response.data.warehouse;
    setWarehouseDeliveryAddress(data);
  } catch (error) {
    console.error("Error fetching warehouse data:", error);
  }
};

export const changeWarehouseAddress = (e, setDeliveryAddress, warehouseDeliveryAddress) => {
  let id = e.target.value;
  const warehouse = warehouseDeliveryAddress.find(w => w.id === id);
  setDeliveryAddress(warehouse);
}

export const fetchProductData = async (setProductData) => {
  const apiUrl = `${config.api_url}/ws-get-products`;
  try {
    const response = await axios.post(apiUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    if (Array.isArray(response.data.products)) {
      const formattedOptions = response.data.products.map(item => ({
        label: `${item.id} ${item.product_desc}`,
        value: item.id,
      }));
      setProductData(formattedOptions);
    //  setProductData(data.product);
    }   
  } catch (error) {
    console.error("Error fetching products data:", error);
  }
};


export const handleChange1 = async (selectedOption, tableRows, setTableRows) => {
  const id = selectedOption;

  console.log('handleChange called with ID:', id);

    // Check if the product already exists
    const found = tableRows.find(element => element.id === id);
    if (found) {
      console.log('Product already exists:', found);
      alert('Product already exists');
        $('#productList').val(''); // Clear input
        return;
      }

    // Clear input
    $('#productList').val('');

    // Prepare form data
    const formData = new FormData();
    formData.append("id", id);

    // API URL
    const apiUrl = `${config.api_url}/ws-get-products-by-id`;

    try {
        // Fetch product data
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        // Parse response
        const data = await response.json();
        console.log('Fetched data:', data);

        // setProduct(data.products);

        // Create new row
        const newRow = {
          id: data.products[0].id,
          code: data.products[0].code,
          product_desc: data.products[0].product_desc,
          pack: data.products[0].pack,
          size: data.products[0].size,
          price: data.products[0].price,
          cost: data.products[0].cost,
          quantity: data.products[0].quantity,
          taxrate: data.products[0].rate,
          vat: parseFloat(data.products[0].vat),
          value: 0 
        };

        console.log('Adding new row:', newRow);

        // Update table rows
        setTableRows(prevRows => [...prevRows, newRow]);
      } catch (error) {
        console.error("Error fetching products data:", error);
      }
    };

    export const handleInputChange = (e, id, field, setTableRows) => {
      const value = acceptNumber(e.target.value);

      setTableRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { 
            ...row, 
            [field]: value, 
            value: field === 'quantity' ? calculateValue(value, row.price) : calculateValue(row.quantity , value) 
          } : row
          )
        );
    };

  // Function to calculate the grand total
  export const calculateGrandTotal = (rows) => {
    return rows.reduce((total, row) => total + (row.value || 0), 0);
  };


  export const removeRow = (id, setTableRows) => {
    setTableRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  export const calculateValue = (quantity, price) => {
    return quantity && price ? quantity * price : 0;
  };

  export const acceptNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  export const getProductBatchData = async (id, setProductBatch) => {
    try {
      // id = 12; 
      const formData = new FormData();
      formData.set('product_id', id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-id`, 
        formData, 
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      setProductBatch(data.batch); 
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };


