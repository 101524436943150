import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
//import Select from 'react-select';


const Inventory = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [options, setOptions] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const elementRef = useRef(null);


  const accessToken = localStorage.getItem('accessToken');
  const modalRef = useRef(null);
  const editModalRef = useRef(null);    
  const tableRef = useRef(null); // Reference to the table element

  const initializeDataTable = () => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (!accessToken) {
        window.location.href = '/login';
        return;
    }

    const $table = $(tableRef.current);
    const searchValue = $('#searchValue').val();

    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }

    $table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: `${config.api_url}/get-products-list`,
            type: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: (d) => ({
                ...d,
                draw: d.draw,
                start: d.start,
                length: d.length,
                searchValue: searchValue,
            }),
            dataSrc: (json) => json.products || []
        },
        columns: [
          { data: "code" },
          { data: "product_desc" },
          { data: "pack" },
          { data: "size" },

          { data: "unit_barcode" },
          { data: "case_barcode" },
          { data: "pallet_qty" },
          { data: "layer_qty" },
          { data: "weight" },

          { data: "cbm" },
          { data: "price" },
          { data: "quantity" },
          { data: "quantity" },
          { data: "quantity" },
          { data: "code" },
          
          { data: null,
            render: function(data, type, row){
              return 0; 
            } 
          }
              ],
          columnDefs: [
            { orderable: false, targets: '_all' }
        ],
        pageLength: 50,
    });
};

useEffect(() => {
    initializeDataTable();
    fetchWarehouseData();
    return () => {
        if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
    };
}, []);




const fetchWarehouseData = async () => {
  const apiUrl = `${config.api_url}/ws-get-warehouse`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        authorization: `Bearer ebu`,
      },
    });
    const data = await response.json();
    setWarehouse(data.warehouse);
    
  } catch (error) {
    console.error("Error fetching warehouse data:", error);
  }
};


  // const fetchData = async () => {
  //   const formData = new FormData();
  //   formData.append('searchByFromdate', fromDate);
  //   formData.append('searchByTodate', toDate);
  //   formData.append('draw', draw);
  //   formData.append('start', start);
  //   formData.append('length', length);
  //   formData.append('role', selectedRole);

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       headers: {
  //         authorization: `Bearer ebu`,
  //       },
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch new sales data');
  //     }

  //     const data = await response.json();
  //     setNewSales(data.data);
  //   } catch (error) {
  //     console.error('Error fetching new sales data:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [fromDate, toDate, draw, start, length, selectedRole]);

  // useEffect(() => {
  //   const table = $('#neworderTable').DataTable({
  //     pageLength: 50,
  //     paging: true,
  //     lengthMenu: [10, 20, 50],
  //     searching: true,
  //     ordering: true,
  //     info: true,
  //     responsive: true,
  //     autoWidth: false,
  //     language: {
  //       paginate: {
  //         first: 'First',
  //         last: 'Last',
  //         next: 'Next',
  //         previous: 'Previous',
  //       },
  //       search: 'Search:',
  //     },
  //     data: newSales,
  //     columns: [
      
  //       { data: 'order_date' },
  //       {
  //           data: 'company_name',
  //           render: (data) => (
  //             `<p ref=${elementRef} data-tip={showTooltip ? data : ''} className="cut-text" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>${data}</p>`
  //           ),
  //         },
  //       {
  //         data: 'reference_no',
  //         render: data => `<a href="#">${data}</a>`,
  //       },
       
        
        
  //     ],
  //   });

  //   return () => {
  //     table.destroy();
  //   };
  // }, [newSales]);

  // useEffect(() => {
  //   const handleMouseEnter = () => {
  //     if (elementRef.current && elementRef.current.offsetWidth < elementRef.current.scrollWidth) {
  //       setShowTooltip(true);
  //     } else {
  //       setShowTooltip(false);
  //     }
  //   };

  //   const element = elementRef.current;
  //   if (element) {
  //     element.addEventListener('mouseenter', handleMouseEnter);
  //     return () => {
  //       element.removeEventListener('mouseenter', handleMouseEnter);
  //     };
  //   }
  // }, [elementRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // fetchData();
  };

  const handleAcceptOrder = async () => {
    const formData = $("#newsalesData").serialize();

    try {
      const response = await fetch(`${config.api_url}/sales/add_orders_front_sheet` , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const result = await response.json();
      if (result.status === 'true') {
        alert('Order Successfully Accepted');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        alert('Please select an order first');
      }
    } catch (error) {
      console.error('Error accepting order:', error);
    }
  };

  const updateCustomer = async () => {
    const formData = $("#updateCustomerForm").serialize();
    try {
      const response = await fetch(`${config.api_url}/ws-updateOrderCustomer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const result = await response.json();
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  const fetchCustomerOptions = async () => {
    try {
      const response = await fetch(`${config.api_url}/ws-CustomerOption`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ebu`,
        },
      });

      const result = await response.json();

      const formattedOptions = result.data.customer.map(item => ({
        key: item.company,
        value: item.id,
      }));

      const $select = $('.customerList');
      $select.empty();
      formattedOptions.forEach(option => {
        const $option = $('<option>', { value: option.value, text: option.key });
        $select.append($option);
      });

      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching customer options:', error);
    }
  };

  return (
    <div>
    <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt;
              <a href="dashboard">Dashboard</a> &gt;
              <span className="last-crumb">Inventory</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
              <div className='inventory'>
                <div className="dashform">
                <div class="topsearch-area">
                  <div style={{ float: 'left', width: '100%' }}>
                    <label>Search Code / Description</label>
                    <input type="text" class="search-area" placeholder="Search" autocomplete="off" value="" />
                    <label>Warehouse</label>
                    <select name="warehouse_id" className="form-select" defaultValue=''>
                        <option value="" disabled>Select Warehouse</option>
                        <option value="all">All</option>
                        {warehouse.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                      <label>Stock Type</label>
                      <select class="form-select" aria-label="Default select example">
                      <option value="all">All</option>
                      <option value="free_stock">Free Stock</option>
                      <option value="on_po">ON PO</option>
                      <option value="on_so">ON SO</option>
                    </select>

                    <button type="submit" onClick={handleAcceptOrder} className="accept-btn">
                  Export To Excel
                </button>
                  </div>
                  </div>
                </div>
                 



                 




                <div className="verical-scrl-wrapper" id="style-3"  style={{ width: '100%' }}>
                  <form>
                    <table className="table table-bordered dataTable" id='inventory_list'ref={tableRef} >
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Product Description</th>
                          <th>Pack</th>
                          <th>Size</th>
                          <th>Unit Barcode</th>
                          <th>Case Barcode</th>
                          <th>Pallet Qty</th>
                          <th>Layer Qty</th>
                          <th>Case Wt</th>
                          <th>CBM</th>
                          <th>Net CPrice</th>
                          <th>F.Stk Value</th>
                          <th>F. Stk</th>
                          <th>On SO</th>
                          <th>Stock</th>
                          <th>WH</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </form>
                </div>
                <div class="newsalesorderftr">
                  <div class="row">
                    <div class="col-6 col-lg-4 col-sm-4"><p className='frtbx'>Total No. of Pallet : 228.5</p></div>
                    <div class="col-6 col-lg-4 col-sm-4"><p className='frtbx'>Free Stock Valuation : 10681.00</p></div>
                    <div class="col-6 col-lg-4 col-sm-4"><p className='frtbx last'>Total Free Stock : 7363</p></div>
                    </div></div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Inventory;
