import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { fetchCustomerData, handleCustomerChange, fetchWarehouseData, handleProductAdd} from '../../services/SalesService';
import { fetchProductData, removeRow, handleInputChange, getProductBatchData  } from '../../services/NewPurchaseOrderService';

const NewSalesOrder = () => {
  const accessToken = localStorage.getItem('accessToken');
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [deliveryAddrDropdown, setDeliveryAddrDropdown] = useState([]);
  const [invoiceAddress, setInvoiceAddress] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);
  const [netWeight, setNetWeight] = useState(0);
  const [cube, setCube] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);
  const [grossProfitPercent, setGrossProfitPercent] = useState(0);
  const navigateToOrderHistory = useNavigate();

  useEffect(() => {
    fetchProductData(setProductData);
    fetchCustomerData(setCustomerData);
    fetchWarehouseData(setWarehouseData);
  }, []);

  useEffect(() => {
    setTotalItems(tableRows.length);

    const newGrandTotal = tableRows.reduce((total, row) => total + row.value, 0);
    setGrandTotal(newGrandTotal);

    const totalVat = tableRows.reduce((vat, row) => vat + row.vat, 0);
    setVatAmt(totalVat);
 
    const totalProfit = tableRows.reduce((profit, row) => profit + row.profit, 0);
    setGrossProfit(totalProfit);

    const stdGoods = tableRows.reduce((total, row) => {
      if (parseFloat(row.vat) > 0) {
        return total + row.value;
      }
      return total; 
    }, 0);

    setStdGoods(stdGoods);

    const zeroGoods = tableRows.reduce((total, row) => {
      if (parseFloat(row.vat) == 0) {
        return total + row.value;
      }
      return total; 
    }, 0);
    setZeroGoods(zeroGoods);

  }, [tableRows]);


  const submitSalesOrderForm = async () => {
    const form = document.getElementById('new_sales_order');
    const formData = new FormData(form);
    
    // Manually append the table rows data
    tableRows.forEach((row, index) => {
      formData.append(`code[${index}]`, row.code);
      formData.append(`packsize[${index}]`, row.pack);
      formData.append(`productSize[${index}]`, row.size);
      formData.append(`qty[${index}]`, row.quantity);
      formData.append(`price[${index}]`, row.price);
      formData.append(`value[${index}]`, row.value);
      formData.append(`Vat[${index}]`, row.vat);
      formData.append(`onSO[${index}]`, row.onSO);
      formData.append(`fstk[${index}]`, row.fstk);
    });

    try {
      const response = await axios.post(`${config.api_url}/ws-sales-add`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });

      const result = response.data;
      Swal.fire({
        title: "Success!",
        text: "Order submitted successfully.",
        icon: "success",
        timer: 1500, // Automatically close after 1500ms
        showConfirmButton: false,
      })

      navigateToOrderHistory('/OrderHistory');
    
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };
  




  return (
    <div>
      <div  className="modal fade" id="save-sales" tabIndex="-1">
        <div  className="modal-dialog modal-md">
          <div  className="modal-content">
            <div  className="modal-header">
              <h5  className="modal-title">&nbsp;</h5>
              <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div  className="modal-body">
              <div  className="add-product-popup">
                <h3> Do you want to create a P.O?</h3>
                <hr/>
                <Link to='NewPurchaseOrder' className="popup-innerbtn-common" onClick={submitSalesOrderForm} >Yes</Link>
                <Link to='#'  className="popup-innerbtn-closebtn">Later</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div  className="modal fade" id="viw-pst-invs" tabIndex="-1">
        <div  className="modal-dialog modal-lg">
          <div  className="modal-content">
            <div  className="modal-header">
              <h5  className="modal-title">&nbsp;</h5>
              <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div  className="modal-body">
            <div  className="partialbox">
                <h3>All Batches : CIF CREAM LEMON (CIF016)   <a href='#' data-bs-toggle="modal" data-bs-target="#vw-pst-ordr">View Past Invoices</a></h3>
              <table  className="table table-bordered dataTable">
                  <thead>
                    <tr>
                    <th>&nbsp;</th>
                    <th>Expiry</th>
                    <th>Stock Qty</th>
                    <th>Sold Qty</th>
                    <th>Bal Qty</th>
                    <th>Alloc</th>
                    <th>UnAlloc</th>
                    <th>PO No.</th>
                    <th>Net Price</th>
                    </tr>
                    </thead>  
                    <tbody>
                    <tr>
                      <td><input  className="" type="radio" name="gridRadios" value="option1" defaultChecked />Select Batch</td> 
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target='_blank'>6484</a></td>
                      <td>09.00</td> 
                      </tr>
                      <tr>
                      <td><input  className="" type="radio" name="gridRadios" value="option1" />Select Batch</td> 
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target='_blank'>6484</a></td>
                      <td>09.00</td> 
                      </tr>

                      <tr>
                      <td><input  className="" type="radio" name="gridRadios" value="option1" />Select Batch</td> 
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target='_blank'>6484</a></td>
                      <td>09.00</td> 
                      </tr>

                      <tr>
                      <td><input  className="" type="radio" name="gridRadios" value="option1" />Select Batch</td> 
                      <td>30/12/1999</td>
                      <td>4050</td>
                      <td>1440</td>
                      <td>2610</td>
                      <td>0</td>
                      <td>2610</td>
                      <td><a href="#" target='_blank'>6484</a></td>
                      <td>09.00</td> 
                      </tr>
                      
                    </tbody>  
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div  className="modal fade" id="vw-pst-ordr" tabIndex="-1">
        <div  className="modal-dialog modal-lg">
          <div  className="modal-content">
            <div  className="modal-header">
              <h5  className="modal-title">&nbsp;</h5>
              <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div  className="modal-body">
            <div  className="partialbox">
                <h3>All Invoices of Product : CIF CREAM LEMON (CIF016)</h3>
              <table  className="table table-bordered dataTable">
                  <thead>
                    <tr>
                    <th>SO#</th>
                    <th>Qty</th>
                    <th>Price </th>
                    <th>ICDate</th>
                    
                    </tr>
                    </thead>  
                    <tbody>
                    <tr>
                      <td>12660</td> 
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>

                    <tr>
                      <td>12660</td> 
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>
                    <tr>
                      <td>12660</td> 
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>
                    <tr>
                      <td>12660</td> 
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>

                    
                      
                    </tbody>  
              </table>
                  
              </div>

            </div>

          </div>
        </div>
      </div>

      <div  className="modal fade" id="adnw-prct" tabIndex="-1">
        <div  className="modal-dialog modal-lg">
          <div  className="modal-content">
            <div  className="modal-header">
              <h5  className="modal-title">&nbsp;</h5>
              <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div  className="modal-body">
            <div  className="partialbox">
                <h3>Search (Code/Description/Unit Barcode)</h3>
              <table  className="table table-bordered dataTable">
                  <thead>
                    <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>PK</th>
                    <th>Size</th>
                    <th>Unit Barcode</th>
                    <th>Stock</th>
                    <th>F.Stk</th>
                    
                    </tr>
                    </thead>  
                    <tbody>
                    <tr>
                      <td><a href="#">SKI010</a></td> 
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td><a href="#">SKI010</a></td> 
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td><a href="#">SKI010</a></td> 
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td><a href="#">SKI010</a></td> 
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                      
                    </tbody>  
              </table>
                  
              </div>

            </div>

          </div>
        </div>
      </div>

      <Header />
      <div  className="breadcrumbs">
        <div  className="container-fluid">
          <div  className="row">
            <div  className="col-12 col-lg-12 col-sm-12"> <a href="#"><i  className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span  className="last-crumb">New Sales Order</span> </div>
          </div>
        </div>
      </div>
      <div  className="main-container">
        <form id='new_sales_order'>
        <div  className="container-fluid">
          <div  className="row">
            <div  className="col-12 col-lg-12 col-sm-12">
              <div  className="show-order-area dashboard">
                <div style={{ marginBottom: '20px', float: 'left' }}>
                <div  className="topsearch-area">                  
                  <div  className="row">
                    <div  className="col-12 col-lg-4 col-sm-4">
                      <div  className="row">
                      <div  className="row mb-3">
                        <label htmlFor="inputText"  className="col-sm-3 col-form-label">Customer:</label>
                        <div  className="col-sm-6">
                          <Select
                            isClearable
                            onChange={(e) => handleCustomerChange(e, setInvoiceAddress, setDeliveryAddrDropdown,setDeliveryAddresses, deliveryAddrDropdown)}
                            options={customerData}
                            placeholder="Select Customer"
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div  className="col-12 col-lg-8 col-sm-8">
                      <div style={{ float: "right" }}>
                      <p><label  className="checkbox"> <input type="checkbox" name='save_as_proforma' /> <span  className="default"></span> </label> <span>Save as Proforma</span></p>
                      </div>
                    </div>
                  </div>
                </div>
                  <div  className="newsalesorderform">
                      <div  className="row mb-3 padd-l-13px">
                        <div  className="col-6 col-lg-6 col-sm-6">
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label"><strong>Invoice Address *</strong></label>
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Address Line 1 </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"id="addr1" value={invoiceAddress.billing_address_line1 } />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Address Line 2 </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  id="addr2" value={invoiceAddress.billing_address_line2 } />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">City </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  value={invoiceAddress.billing_city } />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Country </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  value={invoiceAddress.billing_country } />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Post Code </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  value={invoiceAddress.billing_postcode } />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">VAT </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  />
                            </div>
                          </div>
                          <div  className="row">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Warehouse </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                            <Select
                              name='warehouse_id'
                              id='warehouse_id'
                              isClearable
                              options={warehouseData}
                              placeholder="Select Warehouse"
                              />
                            </div>
                          </div>
                        </div>

                        <div  className="col-6 col-lg-6 col-sm-6">
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label"><strong>Delivery Address </strong></label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                            <Select  isClearable options={deliveryAddrDropdown} placeholder="Select Address" name='deilvery_addr_id' />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Address Line 1 </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"   />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Address Line 2 </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">City </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"   />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Country </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"   />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Post Code </label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control"  />
                            </div>
                          </div>

                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Delivery Date </label>
                            </div>
                            <div  className="col-6 col-lg-2 col-sm-2">
                            <input type="date"  className="form-control" name="delivery_date" id="delivery_date" />
                            </div>
                            <div  className="col-6 col-lg-2 col-sm-2">
                              <label htmlFor="desc"  className="col-form-label">Delivery Time </label>
                            </div>
                            <div  className="col-6 col-lg-2 col-sm-2">
                            <input type="time"  className="form-control" name="delivery_time" id="delivery_time" />
                            </div>
                          </div>
                          <div  className="row mb-3">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Customer Ref *</label>
                            </div>
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <input type="text"  className="form-control" name="customer_ref" id="customer_ref" />
                            </div>
                          </div>
                          <div  className="row">
                            <div  className="col-6 col-lg-6 col-sm-6">
                              <label htmlFor="desc"  className="col-form-label">Job Ref </label>
                            </div>
                            <div  className="col-6 col-lg-2 col-sm-2">
                            <input type="text"  className="form-control" name="job_ref" id="job_ref" />
                            </div>
                            <div  className="col-6 col-lg-2 col-sm-2">
                              <label htmlFor="desc"  className="col-form-label">Booking Ref </label>
                            </div>
                            <div  className="col-6 col-lg-2 col-sm-2">
                            <input type="text"  className="form-control" name="booking_ref" id="booking_ref" />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                        <div className="topsearch-area">
                          <div className="row">
                            <div className="col-12 col-lg-3 col-sm-3">
                                <label htmlFor="inputText" className=" col-form-label" >
                                  Product:
                                </label>
                                <div className="col-sm-8">
                                <Select
                                  isClearable
                                  onChange={(e) => handleProductAdd(e, tableRows,setTableRows)}
                                  options={productData}
                                  placeholder="Select Product"
                                />
                                </div>
                            </div>
                          </div>
                        </div>
                  <div  className="verical-scrl-wrapper common-table newsalesorder-table" id="style-3">
                    <table  className="table table-bordered dataTable" id="dsTable">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Description</th>
                          <th>Pk</th>
                          <th>Size</th>
                          <th>Case Qty</th>
                          <th>Price</th>
                          <th>Cost</th>
                          <th>Value</th>
                          <th>Profit</th>
                          <th>VAT</th>
                          <th>Stock</th>
                          <th>On PO</th>
                          <th>F.Stk</th>
                          <th>Mapped P.O No.</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {tableRows.length === 0 ? (
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan={13}>No product added</td>
                              </tr>
                            ) : (
                              tableRows.map((row) => (
                                <tr key={row.id}>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="product_id[]"
                                      value={row.id}
                                    />
                                    <input type="hidden"  name="code[]"  value={row.code}  />
                                    <Link
                                      to="#"
                                      onClick={() => getProductBatchData(row.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#viw-pst-invs"
                                    >
                                      {row.code}
                                    </Link>
                                  </td>
                                  <td>
                                    <p className="cut-text">
                                      <a
                                        data-bs-toggle="modal"
                                        className="applybtn"
                                        data-id={row.id}
                                        data-bs-target="#viewproduct"
                                      >
                                        {row.product_desc}
                                      </a>
                                    </p>
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="packsize[]"
                                      value={row.pack}
                                    />{" "}
                                    {row.pack}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="productSize[]"
                                      value={row.size}
                                    />{" "}
                                    {row.size}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="qty[]"
                                      className="form-control qtyinputbox"
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "quantity", setTableRows )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="price[]"
                                      className="form-control qtyinputbox"
                                      value={row.price}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          row.id,
                                          "price",
                                          setTableRows
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                      {row.cost}  
                                  </td>
                                  <td>
                                    {row.value.toFixed(2)}
                                  </td>
                                  <td>
                                    <input type="hidden" name="profit[]" value={(row.price - row.cost).toFixed(2)} />
                                    { (row.price - row.cost).toFixed(2) }
                                  </td>
                                  <td>
                                    <input type="hidden" name="vat[]" value={((row.value / 100) * row.vat).toFixed(2)} /> 
                                    {((row.value / 100) * row.vat).toFixed(2)}
                                  </td>
                                  <td>
                                    <input type="hidden" name="stock[]" value={row.quantity} />
                                    {row.quantity}
                                  </td>
                                  <td>
                                    <input type="hidden"  name="onPO[]" />
                                    0
                                  </td>
                                  <td>
                                    <input  type="hidden"  name="onSO[]"  value="0" />
                                    0
                                  </td>
                                  <td>
                                    <input type="hidden"  name="fstk[]" value="0" />
                                    0
                                  </td>
                                  <td>
                                    <Link
                                      To="#"
                                      value="Remove"
                                      onClick={() =>
                                        removeRow(row.id, setTableRows)
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Link>
                                  </td>
                                  <input type="hidden"  name="stdGoods" value={stdGoods} />
                                  <input type="hidden"  name="vatAmt" value={vatAmt} />
                                  <input type="hidden"  name="zeroGoods" value={zeroGoods} />
                                  <input type="hidden"  name="grandTotal" value={grandTotal} />
                                  <input type="hidden"  name="totalItems" value={totalItems} />
                                  <input type="hidden"  name="grossWeight" value={grossWeight} />
                                  <input type="hidden"  name="netWeight" value={netWeight} />
                                  <input type="hidden"  name="cube" value={cube} />
                                  <input type="hidden"  name="grossProfit" value={grossProfit} />
                                  <input type="hidden"  name="grossProfitPercent" value={grossProfitPercent} />                                  
                                </tr>
                              ))
                            )}
                      </tbody>
                    </table>
                  </div>
                  <div  className="newsalesorderftr">
                    <div  className="row">
                      <div  className="col-6 col-lg-3 col-sm-3">Std Rated : {stdGoods.toFixed(2)}</div>
                      <div  className="col-6 col-lg-3 col-sm-3">VAT Amount : {vatAmt.toFixed(2)}</div>
                      <div  className="col-6 col-lg-3 col-sm-3">Zero Rated : {zeroGoods.toFixed(2)}</div>
                      <div  className="col-6 col-lg-3 col-sm-3">Grand Total : {grandTotal.toFixed(2)}</div>
                    </div>
                  </div>
                  <div  className="newsalesorderftr">
                    <div  className="row">
                      <div  className="col-6 col-lg-3 col-sm-3">Total Items : {totalItems}</div>
                      <div  className="col-6 col-lg-3 col-sm-3">Gross Weight : {grossWeight} KGS</div>
                      <div  className="col-6 col-lg-3 col-sm-3">Net Weight : {netWeight} KGS</div>
                      <div  className="col-6 col-lg-3 col-sm-3">Cube : {cube.toFixed(2)} M3</div>
                    </div>
                  </div>
                  <div  className="newsalesorderftr">
                    <div  className="row">
                      <div  className="col-6 col-lg-6 col-sm-6">Gross Profit : {grossProfit.toFixed(2)}</div>
                      <div  className="col-6 col-lg-6 col-sm-6">Gross Profit % : {grossProfitPercent}%</div>
                     </div>
                  </div>
                  <div>
                  <div  className="row mb-3">
                    <div  className="col-6 col-lg-3 col-sm-3">
                      <label htmlFor="desc"  className="col-form-label">Notes :</label></div>
                      <div  className="col-6 col-lg-9 col-sm-9">
                        <textarea  className="form-control" name="notes" id="notes" placeholder="Leave a comment here" style={{ height: '100px'}}></textarea>
                        </div>
                        </div>
                  </div>
                  <a href="#"  className="popup-innerbtn-common" data-bs-toggle="modal" data-bs-target="#save-sales">Save Sales Order</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default NewSalesOrder; 